//@flow
import React from "react";

type ProactiveMonitoringProps = {
  color?: string,
};

const ProactiveMonitoring = (props: ProactiveMonitoringProps) => {
  return (
    <svg id="Layer_1" data-name="Layer 1" viewBox="0 0 566.93 566.93">
      <path
        className="cls-1"
        fill={props.color === "white" ? "#fff" : "#6d55ff"}
        d="M539.5,0H429.77a27.47,27.47,0,0,0-27.43,27.43v73.15A27.47,27.47,0,0,0,429.77,128H539.5a27.47,27.47,0,0,0,27.43-27.44V27.43A27.47,27.47,0,0,0,539.5,0ZM450.62,109.73l8.8-35.18a26,26,0,0,1,50.43,0l8.8,35.18Zm98-9.15a9.15,9.15,0,0,1-9.14,9.15h-2l-9.9-39.62a44.28,44.28,0,0,0-85.91,0l-9.9,39.62h-2a9.16,9.16,0,0,1-9.15-9.15V27.43a9.15,9.15,0,0,1,9.15-9.14H539.5a9.14,9.14,0,0,1,9.14,9.14Z"
      />
      <path
        className="cls-2"
        fill={props.color === "white" ? "#fff" : "#6d55ff"}
        d="M338.33,0H228.6a27.47,27.47,0,0,0-27.43,27.43v73.15A27.47,27.47,0,0,0,228.6,128H338.33a27.47,27.47,0,0,0,27.43-27.44V27.43A27.47,27.47,0,0,0,338.33,0Zm9.14,100.58a9.15,9.15,0,0,1-9.14,9.15H228.6a9.15,9.15,0,0,1-9.14-9.15V27.43a9.14,9.14,0,0,1,9.14-9.14H338.33a9.14,9.14,0,0,1,9.14,9.14Z"
      />
      <path
        className="cls-3"
        fill={props.color === "white" ? "#fff" : "#6d55ff"}
        d="M137.16,0H27.43A27.47,27.47,0,0,0,0,27.43v73.15A27.47,27.47,0,0,0,27.43,128H137.16a27.47,27.47,0,0,0,27.43-27.44V27.43A27.47,27.47,0,0,0,137.16,0Zm9.14,100.58a9.15,9.15,0,0,1-9.14,9.15H27.43a9.15,9.15,0,0,1-9.14-9.15V27.43a9.14,9.14,0,0,1,9.14-9.14H137.16a9.14,9.14,0,0,1,9.14,9.14Z"
      />
      <path
        className="cls-4"
        fill={props.color === "white" ? "#fff" : "#6d55ff"}
        d="M539.5,164.59H429.77A27.47,27.47,0,0,0,402.34,192v73.16a27.47,27.47,0,0,0,27.43,27.43H539.5a27.47,27.47,0,0,0,27.43-27.43V192A27.47,27.47,0,0,0,539.5,164.59Zm9.14,100.59a9.14,9.14,0,0,1-9.14,9.14H429.77a9.15,9.15,0,0,1-9.15-9.14V192a9.15,9.15,0,0,1,9.15-9.14H539.5a9.14,9.14,0,0,1,9.14,9.14Z"
      />
      <path
        className="cls-5"
        fill={props.color === "white" ? "#fff" : "#6d55ff"}
        d="M338.33,164.59H228.6A27.47,27.47,0,0,0,201.17,192v66.41a70.89,70.89,0,0,0-36.58,0V192a27.47,27.47,0,0,0-27.43-27.43H27.43A27.47,27.47,0,0,0,0,192v73.16a27.47,27.47,0,0,0,27.43,27.43h92.2a72.78,72.78,0,0,0,15.9,92.23A82.36,82.36,0,0,0,64,466.34V566.93H274.32V437.7l1.21-.18a73.52,73.52,0,0,0,62.8-72.42V292.61a27.47,27.47,0,0,0,27.43-27.43V192A27.47,27.47,0,0,0,338.33,164.59ZM182.88,274.32a54.94,54.94,0,0,1,50.42,33.27l-32.13-16.06-4.09,2.05a164.88,164.88,0,0,1-65.78,17.1A54.91,54.91,0,0,1,182.88,274.32Zm-155.45,0a9.14,9.14,0,0,1-9.14-9.14V192a9.14,9.14,0,0,1,9.14-9.14H137.16A9.14,9.14,0,0,1,146.3,192v73.91a72.43,72.43,0,0,0-11.64,8.39ZM128,329.18s0-.08,0-.11a183.47,183.47,0,0,0,73.07-17.13l36.59,18.28a54.84,54.84,0,0,1-109.67-1Zm118.11-36.57h28.19v59.15a27.49,27.49,0,0,1-24,27.23l-15.78,2a72.83,72.83,0,0,0,11.62-88.35ZM320,365.11a55.1,55.1,0,0,1-47.1,54.3L256,421.84v126.8H128V438.91H109.73V548.64H82.3v-82.3a64.08,64.08,0,0,1,64-64h64l42.25-5.22a45.78,45.78,0,0,0,40.05-45.36V251.46a13.72,13.72,0,1,1,27.43,0Zm27.43-99.93a9.14,9.14,0,0,1-9.14,9.14V251.46a32,32,0,0,0-64,0v22.86H231.1a72.6,72.6,0,0,0-11.64-8.39V192a9.14,9.14,0,0,1,9.14-9.14H338.33a9.14,9.14,0,0,1,9.14,9.14Z"
      />
      <path
        className="cls-6"
        fill={props.color === "white" ? "#fff" : "#6d55ff"}
        d="M36.58,201.17H128v18.29H36.58Z"
      />
      <path
        className="cls-7"
        fill={props.color === "white" ? "#fff" : "#6d55ff"}
        d="M109.73,237.75H128V256H109.73Z"
      />
      <path
        className="cls-8"
        fill={props.color === "white" ? "#fff" : "#6d55ff"}
        d="M36.58,237.75H91.44V256H36.58Z"
      />
      <path
        className="cls-4"
        fill={props.color === "white" ? "#fff" : "#6d55ff"}
        d="M484.63,201.17a27.43,27.43,0,1,0,27.44,27.43A27.47,27.47,0,0,0,484.63,201.17Zm0,36.57a9.14,9.14,0,1,1,9.15-9.14A9.14,9.14,0,0,1,484.63,237.74Z"
      />
      <path
        className="cls-9"
        fill={props.color === "white" ? "#fff" : "#6d55ff"}
        d="M237.74,36.58H256V91.44H237.74Z"
      />
      <path
        className="cls-10"
        fill={props.color === "white" ? "#fff" : "#6d55ff"}
        d="M274.32,73.15h18.29V91.44H274.32Z"
      />
      <path
        className="cls-11"
        fill={props.color === "white" ? "#fff" : "#6d55ff"}
        d="M310.9,54.87h18.28V91.44H310.9Z"
      />
      <path
        className="cls-12"
        fill={props.color === "white" ? "#fff" : "#6d55ff"}
        d="M64,36.58A27.43,27.43,0,1,0,91.44,64,27.47,27.47,0,0,0,64,36.58Zm0,36.57A9.15,9.15,0,1,1,73.15,64,9.15,9.15,0,0,1,64,73.15Z"
      />
      <path
        className="cls-13"
        fill={props.color === "white" ? "#fff" : "#6d55ff"}
        d="M109.73,36.58H128V54.87H109.73Z"
      />
      <path
        className="cls-14"
        fill={props.color === "white" ? "#fff" : "#6d55ff"}
        d="M109.73,73.15H128V91.44H109.73Z"
      />
    </svg>
  );
};

export default ProactiveMonitoring;