//@flow
import React from "react";

type ServerRoomProps = {
  color?: string,
};

const ServerRoom = (props: ServerRoomProps) => {
  return (
    <svg id="Layer_1" data-name="Layer 1" viewBox="0 0 566.93 566.93">
      <path
        className="cls-1"
        fill={props.color === "white" ? "#fff" : "#6d55ff"}
        d="M310.9,219.46H109.73V310.9H310.9Zm-18.29,73.15H128V237.74H292.61Z"
      />
      <path
        className="cls-2"
        fill={props.color === "white" ? "#fff" : "#6d55ff"}
        d="M256,256h18.29v18.29H256Z"
      />
      <path
        className="cls-3"
        fill={props.color === "white" ? "#fff" : "#6d55ff"}
        d="M182.88,256h18.29v18.29H182.88Z"
      />
      <path
        className="cls-4"
        fill={props.color === "white" ? "#fff" : "#6d55ff"}
        d="M219.46,256h18.28v18.29H219.46Z"
      />
      <path
        className="cls-5"
        fill={props.color === "white" ? "#fff" : "#6d55ff"}
        d="M310.9,438.91H109.73v91.44H310.9Zm-18.29,73.16H128V457.2H292.61Z"
      />
      <path
        className="cls-6"
        fill={props.color === "white" ? "#fff" : "#6d55ff"}
        d="M219.46,475.49h18.28v18.29H219.46Z"
      />
      <path
        className="cls-7"
        fill={props.color === "white" ? "#fff" : "#6d55ff"}
        d="M182.88,475.49h18.29v18.29H182.88Z"
      />
      <path
        className="cls-8"
        fill={props.color === "white" ? "#fff" : "#6d55ff"}
        d="M146.3,475.49h18.29v18.29H146.3Z"
      />
      <path
        className="cls-9"
        fill={props.color === "white" ? "#fff" : "#6d55ff"}
        d="M310.9,329.18H109.73v91.44H310.9Zm-18.29,73.16H128V347.47H292.61Z"
      />
      <path
        className="cls-10"
        fill={props.color === "white" ? "#fff" : "#6d55ff"}
        d="M182.88,365.76h18.29v18.29H182.88Z"
      />
      <path
        className="cls-11"
        fill={props.color === "white" ? "#fff" : "#6d55ff"}
        d="M219.46,365.76h18.28v18.29H219.46Z"
      />
      <path
        className="cls-12"
        fill={props.color === "white" ? "#fff" : "#6d55ff"}
        d="M256,365.76h18.29v18.29H256Z"
      />
      <path
        className="cls-13"
        fill={props.color === "white" ? "#fff" : "#6d55ff"}
        d="M438.91,356.62a45.72,45.72,0,1,0,45.72-45.72A45.77,45.77,0,0,0,438.91,356.62Zm45.72-27.44a27.44,27.44,0,1,1-27.43,27.44A27.47,27.47,0,0,1,484.63,329.18Z"
      />
      <path
        className="cls-14"
        fill={props.color === "white" ? "#fff" : "#6d55ff"}
        d="M539.5,201.17a27.47,27.47,0,0,0,27.43-27.43V64A27.46,27.46,0,0,0,539.5,36.58H356.62A27.46,27.46,0,0,0,329.18,64V146.3H234.47A118.86,118.86,0,1,0,36.58,204.44V525l41.93,41.94h263.6L384.05,525V365.76h18.29v15.55l12.08,4.39L409,397.34l34.93,34.93,11.64-5.44,4.39,12.08h49.4l4.38-12.08,11.64,5.44,34.93-34.93-5.45-11.64,12.1-4.39V331.93l-12.08-4.39,5.45-11.64L525.37,281l-11.64,5.44-4.4-12.09H493.78V201.17ZM347.47,64a9.16,9.16,0,0,1,9.15-9.15H539.5A9.16,9.16,0,0,1,548.64,64V173.74a9.16,9.16,0,0,1-9.14,9.14H408.27L365.61,217a11.16,11.16,0,0,1-18.14-8.72V64ZM329.18,208.29V548.64H91.44V201.17H329.18Zm-157-62q.93-9,1.25-18.28H219a100.22,100.22,0,0,1-3.43,18.28ZM18.75,109.73A99.6,99.6,0,0,1,29.4,73.15H68a250.3,250.3,0,0,0-3.74,36.58ZM86.69,73.15h64.36a227.5,227.5,0,0,1,4.21,36.58H82.49A226.42,226.42,0,0,1,86.69,73.15Zm32.18-54.86c9.83,0,20.3,14,27.52,36.57h-55C98.57,32.25,109,18.29,118.87,18.29ZM219,109.73H173.54a253.09,253.09,0,0,0-3.74-36.58h38.55A99.59,99.59,0,0,1,219,109.73ZM84,146.3q-1-9-1.33-18.28h72.5q-.33,9.3-1.34,18.28ZM196.4,54.86H165.56a126.14,126.14,0,0,0-12.72-30.55A101.06,101.06,0,0,1,196.4,54.86ZM84.91,24.31A126.94,126.94,0,0,0,72.19,54.86H41.34A101.09,101.09,0,0,1,84.91,24.31ZM18.77,128H64.33q.38,10.92,1.58,21.45a45.68,45.68,0,0,0-27.33,29.87A100.76,100.76,0,0,1,18.77,128Zm36.09,64A27.47,27.47,0,0,1,82.3,164.59H329.18v18.29H91.44a18.3,18.3,0,0,0-18.29,18.29V535.71L54.86,517.42ZM347.47,535.71V235.51a29.07,29.07,0,0,0,11.17,2.23,29.38,29.38,0,0,0,7.12-1V517.42ZM499.25,300.13l1.94,4.09,9.32,3.87,11-5.14,16.81,16.8-5.12,10.95,4.07,9.89,11.39,4.15V368.5l-7.52,2.74-4.06,1.86-3.88,9.43,5.12,11-16.81,16.79-11-5.14-9.85,4.09-4.15,11.39H472.75L470,413.1,468.07,409l-9.31-3.87-11,5.14L431,393.49l5.12-11L432,372.65l-11.4-4.16V344.73l7.53-2.73,4.05-1.87,3.89-9.43L431,319.75l16.8-16.8,11,5.14L468.6,304l4.15-11.39h23.76Zm-23.76-25.81H459.93l-4.38,12.08L443.91,281,409,315.89l5.45,11.64-12.09,4.39v15.55H384.05V225.68l30.64-24.51h60.8Z"
      />
      <path
        className="cls-15"
        fill={props.color === "white" ? "#fff" : "#6d55ff"}
        d="M365.76,73.15H530.35V91.44H365.76Z"
      />
      <path
        className="cls-16"
        fill={props.color === "white" ? "#fff" : "#6d55ff"}
        d="M365.76,109.73h128V128h-128Z"
      />
      <path
        className="cls-17"
        fill={props.color === "white" ? "#fff" : "#6d55ff"}
        d="M512.07,109.73h18.28V128H512.07Z"
      />
      <path
        className="cls-18"
        fill={props.color === "white" ? "#fff" : "#6d55ff"}
        d="M402.34,146.3h18.28v18.29H402.34Z"
      />
      <path
        className="cls-19"
        fill={props.color === "white" ? "#fff" : "#6d55ff"}
        d="M438.91,146.3H457.2v18.29H438.91Z"
      />
      <path
        className="cls-20"
        fill={props.color === "white" ? "#fff" : "#6d55ff"}
        d="M365.76,146.3h18.29v18.29H365.76Z"
      />
      <path
        className="cls-21"
        fill={props.color === "white" ? "#fff" : "#6d55ff"}
        d="M512.07,146.3h18.28v18.29H512.07Z"
      />
      <path
        className="cls-22"
        fill={props.color === "white" ? "#fff" : "#6d55ff"}
        d="M475.49,146.3h18.29v18.29H475.49Z"
      />
    </svg>
  );
};

export default ServerRoom;
