import React, { Component } from "react";
import Animate from "animate.css-react";
import { Link } from "react-router-dom";

import Header from "../../components/Header";
import Footer from "../../components/Footer";
import Arrow from "../../components/Icons/Arrow";
import Next from "../../components/Icons/Next";
import Button from "../../components/Form/Button";

import HomeBg1 from "../../assets/images/home/home-bg-1.png";
import HomeBg2 from "../../assets/images/home/home-bg-2.png";
import HomeBg3 from "../../assets/images/home/home-bg-3.jpg";
import HomeBg4 from "../../assets/images/home/home-bg-4.jpg";
import AtlanticoLogo from "../../assets/images/home/atlantico-logo.png";
import CybersocLogo from "../../assets/images/home/cybersoc-logo.png";
import NibbsLogo from "../../assets/images/home/nibbs-logo.png";
import TotalcoopLogo from "../../assets/images/home/totalcoop-logo.png";
import UbaLogo from "../../assets/images/home/uba-logo.png";
import ImpactTotalcoop from "../../assets/images/home/impact-totalcoop.png";
import ImpactCybersoc from "../../assets/images/home/impact-cybersoc.png";
import ImpactUba from "../../assets/images/home/impact-uba.png";
import ImpactAba from "../../assets/images/home/impact-aba.png";
import ClientStarsight from "../../assets/images/home/clients/starsight.png";
import ClientKorapay from "../../assets/images/home/clients/korapay.png";
import ClientSoftcom from "../../assets/images/home/clients/Softcom.png";
import ClientTreasures from "../../assets/images/home/clients/awesome-treasures.png";
import ClientDeimos from "../../assets/images/home/clients/deimos.png";
import ClientRedwire from "../../assets/images/home/clients/Redwire.png";
import ClientAdconsult from "../../assets/images/home/clients/Adconsult.png";
import ClientInterswitch from "../../assets/images/home/clients/interswitch.svg";

import "animate.css/animate.css";

class Home extends Component {
  state = {
    slider: 1,
    sliderContent: null,
  };

  componentDidMount() {
    window.scrollTo(0, 0);
    this.handleConfigureSlider(1);
    this.timer = setInterval(() => {
      this.handleOnNext();
    }, 13000);
  }

  componentWillUnmount() {
    clearInterval(this.timer);
  }

  handleOnNext = () => {
    let slider = 0;
    if (this.state.slider + 1 > 4) {
      slider = 1;
    } else {
      slider = this.state.slider + 1;
    }

    this.setState({ slider });
    this.handleConfigureSlider(slider);
  };

  handleConfigureSlider = (slider) => {
    let content = null;
    switch (slider) {
      case 2:
        content = (
          <div
            className="Home__header"
            style={{ backgroundImage: `url(${HomeBg2})` }}
          >
            <div className="Home__header__content">
              <h2>IT Procurement</h2>
              <br />
              <p>
                Supply of Servers, Laptops, Mobile Devices & many more. Save on
                the best deals when you buy in bulk.
              </p>
            </div>
          </div>
        );
        break;

      case 3:
        content = (
          <div
            className="Home__header"
            style={{ backgroundImage: `url(${HomeBg3})` }}
          >
            <div
              className="Home__header__content"
              style={{ backgroundColor: "#00000080", height: "750px" }}
            >
              <h2>Hardware Repair & Maintenance.</h2>
              <br />
              <p>
                We repair computers, cell phones and tablets, making your device
                function like brand new again.
              </p>
            </div>
          </div>
        );
        break;

      case 4:
        content = (
          <div
            className="Home__header"
            style={{ backgroundImage: `url(${HomeBg4})` }}
          >
            <div
              className="Home__header__content"
              style={{ paddingTop: "200px" }}
            >
              <h2>Track All Your Devices, Software & Data.</h2>
              <br />
              <p style={{ maxWidth: "400px" }}>
                Built into the BIOS firmware during the manufacturing process of
                most major device manufacturers, our customers get the only
                security system that can withstand everything from a total
                factory reset to a complete hard drive replacement.
              </p>
            </div>
          </div>
        );
        break;

      default:
        content = (
          <div
            className="Home__header"
            style={{ backgroundImage: `url(${HomeBg1})` }}
          >
            <div
              className="Home__header__content"
              style={{ paddingTop: "280px" }}
            >
              <h2>Work With IT Experts</h2>
              <br />
              <p>
                Tinc helps businesses of all sizes grow, innovate, cut cost and
                remain secure through the use of technology.
              </p>
            </div>
          </div>
        );
    }

    this.setState({ sliderContent: content });
  };

  render() {
    return (
      <div className="Home">
        <Header hasOverlay />
        <Animate
          enter="bounceIn"
          leave="bounceOut"
          appear="bounceIn"
          change="slideInLeft"
          durationAppear={100}
          durationChange={500}
          component="div"
        >
          {this.state.sliderContent}
        </Animate>

        <div className="Home__header__logos">
          <img src={NibbsLogo} alt="Nibbs Logo" />
          <img src={UbaLogo} alt="Uba Logo" />
          <img src={AtlanticoLogo} alt="Atlantico Logo" />
          <img src={CybersocLogo} alt="Cybersoc Logo" />
          <img src={TotalcoopLogo} alt="Totalcoop Logo" />
        </div>

        <div className="Home__header__next" onClick={() => this.handleOnNext()}>
          <Next />
        </div>

        <section id="services" className="Home__services">
          <div className="row Home__services__main">
            <div className="col-md-6">
              <div className="row Home__services__sub">
                <div className="col-md-6">
                  <div className="Home__services__card">
                    <h5>IT Consultancy</h5>
                    <p>
                      Tinc helps businesses of all sizes grow, innovate, cut
                      costs and remain secure through the use of technology.
                    </p>

                    <div className="Home__services__card--more">
                      <Link to="/consultancy">
                        Learn More <Arrow color="black" />
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 Home__services__sub--down">
                  <div className="Home__services__card">
                    <h5>End-to-End Business Support.</h5>
                    <p>
                      We provide a full range of flexible IT support services
                      that keep organisations operational and ensure deployed
                      systems are operating optimally.
                    </p>

                    <div className="Home__services__card--more">
                      <Link to="/repair">
                        Learn More <Arrow color="black" />
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 Home__services__sub--up">
                  <div className="Home__services__card">
                    <h5>Hardware Repair & Maintenance</h5>
                    <p>
                      With a professionally certified technical team, we provide
                      industry leading cellphone and computer repair services.
                      We will make your device work like its brand new.
                    </p>

                    <div className="Home__services__card--more">
                      <Link to="/repair">
                        Learn More <Arrow color="black" />
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 Home__services__sub--down">
                  <div className="Home__services__card">
                    <h5>IT Procurement</h5>
                    <p>
                      We provide a full range of flexible IT support services
                      that keep organisations operational and ensure deployed
                      systems are operating optimaly.
                    </p>

                    <div className="Home__services__card--more">
                      <Link to="/procurement">
                        Learn More <Arrow color="black" />
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 Home__services__content">
              <h3>Industry Leading & Bespoke IT Solutions.</h3>
              <div className="Home__divider" />
              <p>
                Our Solutions are bespoke, affordable and durable ensuring that
                your business never lacks the technical support it needs to be
                continously productive.
                <br />
                <br />
                Learn more about the suport we provide for businesses.
              </p>
              <br />

              <Link to="/services">
                <Button>
                  Get Started <Arrow color="black" />
                </Button>
              </Link>
            </div>
          </div>
        </section>

        <section id="impact" className="Home__impact">
          <div className="Home__impact__inner">
            <small>What we have done</small>
            <div className="Home__divider" />
            <h3>
              These Top companies can focus on their businesses while we focus
              on their IT needs.
            </h3>
            <br />
            <Link to="/projects">
              <Button>
                Our Impact
                <Arrow color="black" />
              </Button>
            </Link>

            <div
              className="Projects__group"
              style={{ padding: "30px 20px", paddingLeft: "0" }}
            >
              <div className="row">
                <div className="col-md-7">
                  <div className="Projects__item ">
                    <Link to="/project/totalcoop">
                      <img src={ImpactTotalcoop} alt="Totalcoop impact" />
                      <div className="Projects__item--hover">
                        <div className="Projects__item--hover__content">
                          <small>Total COOP</small>
                          <br />
                          <h4>Teleconferencing Solutions</h4>
                        </div>
                      </div>
                    </Link>
                  </div>
                </div>

                <div className="col-md-5">
                  <div className="Projects__item ">
                    <Link to="/project/cybersoc1">
                      <img src={ImpactCybersoc} alt="Cybersoc impact" />
                      <div className="Projects__item--hover">
                        <div className="Projects__item--hover__content">
                          <small>Cybersoc Africa</small>
                          <br />
                          <h4>Server Room Setup</h4>
                        </div>
                      </div>
                    </Link>
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="Projects__item ">
                    <Link to="/project/uba">
                      <img src={ImpactUba} alt="Uba impact" />
                      <div className="Projects__item--hover">
                        <div className="Projects__item--hover__content">
                          <small>UBA</small>
                          <br />
                          <h4>Servicing Global Brands</h4>
                        </div>
                      </div>
                    </Link>
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="Projects__item ">
                    <Link to="/project/arabi">
                      <img src={ImpactAba} alt="ABA impact" />
                      <div className="Projects__item--hover">
                        <div className="Projects__item--hover__content">
                          <small>Arabi Bello & Associates</small>
                          <br />
                          <h4>Custom Built Unreal Performance</h4>
                        </div>
                      </div>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section id="info" className="Home__info">
          <div className="Home__info__inner">
            <h3>
              Tinc IT is a technology company with a focus on providing support
              to emerging and already established organizations.{" "}
            </h3>
            <p>
              We strongly believe technology plays a vital role in any business
              whether small or big. We also understand that effectively utilized
              technology is an asset that boosts productivity and the overall
              bottom line of any business.
            </p>
          </div>
        </section>

        <section id="clients" className="Home__clients">
          <h3>Some Of Our Clients</h3>
          <div className="Home__clients__items">
            <div className="Home__clients__row">
              <div className="Home__clients__item">
                <img src={ClientStarsight} alt="Client Cybersoc" />
              </div>

              <div className="Home__clients__item">
                <img src={ClientKorapay} alt="Client Korapay" />
              </div>

              <div className="Home__clients__item">
                <img src={ClientSoftcom} alt="Client Softcom" />
              </div>

              <div className="Home__clients__item">
                <img src={ClientTreasures} alt="Client Treasures" />
              </div>
            </div>

            <div className="Home__clients__row">
              <div className="Home__clients__item">
                <img src={ClientDeimos} alt="Client Deimos" />
              </div>

              <div className="Home__clients__item">
                <img src={ClientRedwire} alt="Client Redwire" />
              </div>

              <div className="Home__clients__item">
                <img src={ClientAdconsult} alt="Client Adconsult" />
              </div>

              <div className="Home__clients__item">
                <img src={ClientInterswitch} alt="Client Interswitch" />
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </div>
    );
  }
}

export default Home;
