//@flow
import React from "react";

type AccessControlProps = {
  color?: string,
};

const AccessControl = (props: AccessControlProps) => {
  return (
    <svg id="Layer_1" data-name="Layer 1" viewBox="0 0 566.93 566.93">
      <path
        className="cls-1"
        fill={props.color === "white" ? "#fff" : "#6d55ff"}
        d="M81.6,0V523.93H306.75l-9.1-18.71H99.79v-231H92V195.72h7.8v-177H463.63V505.22h-24.7v18.71h43V0Z"
      />
      <path
        className="cls-2"
        fill={props.color === "white" ? "#fff" : "#6d55ff"}
        d="M297.65,505.22H38.2v18.71H306.75Zm141.28,0v18.71h89.72V505.22Z"
      />
      <path
        className="cls-3"
        fill={props.color === "white" ? "#fff" : "#6d55ff"}
        d="M432.4,542.68l-8.6,11.8H336a23.11,23.11,0,0,1-20.1-11.8H0v18.71H566.93V542.68Z"
      />
      <path
        className="cls-4"
        fill={props.color === "white" ? "#fff" : "#6d55ff"}
        d="M463.63,48.63,99.79,422.87,81.6,441.57V468l18.19-18.71L463.63,75.09l18.26-18.78V29.84Z"
      />
      <path
        className="cls-5"
        fill={props.color === "white" ? "#fff" : "#6d55ff"}
        d="M281.58,501.72l-1.46-3h0l2,4.11Z"
      />
      <path
        className="cls-6"
        fill={props.color === "white" ? "#fff" : "#6d55ff"}
        d="M432.88,454.56h-5.4a20.7,20.7,0,0,0-18.82-12.45H397.21a20.7,20.7,0,0,0-18.82-12.45h-9.61V416H405.1V311.34H393V288.93c0-28.85-22.85-52.32-50.94-52.32s-50.93,23.47-50.93,52.32v22.41H279V416h42.38v50.74l-.93-1.58a25,25,0,0,0-34-9.26A26.36,26.36,0,0,0,276,490.24l4.13,8.48,1.46,3,.53,1.11.43.89,19.51,40.15a38.39,38.39,0,0,0,13.76,15.62,37,37,0,0,0,20.09,5.94h95.22l18.43-25.28,4-5.22,0-31.21v-28C453.54,464.07,444.27,454.56,432.88,454.56ZM308.24,288.93c0-19.23,15.17-34.87,33.82-34.87s33.83,15.64,33.83,34.87v22.41H308.24ZM296.13,398.52V328.79H388v69.73H368.78V385.45c0-13.39-10.63-24.29-23.69-24.29s-23.68,10.9-23.68,24.29v13.07Zm140.3,130.39L422.53,548h-86.6a20.65,20.65,0,0,1-17.83-10.51c-.24-.44-.48-.89-.71-1.35l-15.75-32.4-2.43-5-7.89-16.22a8.9,8.9,0,0,1-.33-7,8.47,8.47,0,0,1,4.68-4.92,7.87,7.87,0,0,1,3.08-.61,8.17,8.17,0,0,1,7.06,4.12l17.1,29h15.6V385.45a6.58,6.58,0,1,1,13.16,0v80.34h17.11V447.11h9.61a3.64,3.64,0,0,1,3.55,3.73v27.41h17.11V459.56h9.61a3.65,3.65,0,0,1,3.56,3.73V490.7h17.1V472h3.56a3.64,3.64,0,0,1,3.55,3.73Z"
      />
      <path
        className="cls-7"
        fill={props.color === "white" ? "#fff" : "#6d55ff"}
        d="M299.21,498.72l2.43,5h0Zm-17.1,4.11.42.89h0Z"
      />
      <path
        className="cls-8"
        fill={props.color === "white" ? "#fff" : "#6d55ff"}
        d="M359.34,109.3a7.89,7.89,0,0,0-4.15-4.09l-1.06-.43-199.43-83a7.16,7.16,0,0,0-5.79.1,8.15,8.15,0,0,0-4.26,4.88L122.46,94.63A8.45,8.45,0,0,0,127,105.18l20.88,8.62L143.51,127A8.48,8.48,0,0,0,148,137.58l44.1,18.21-2.61,8c-3.31,10-.46,21.2,6.92,27.8v24.35h-62a28.52,28.52,0,0,0-16.78-15.46v-9.6c0-4.59-3.46-8.33-7.71-8.33H90c-4.25,0-7.71,3.74-7.71,8.33v89c0,4.59,3.46,8.32,7.71,8.32h20c4.25,0,7.71-3.73,7.71-8.32v-9.59a28.57,28.57,0,0,0,16.78-15.47h62v2.81c0,10.73,7.9,19.45,17.61,19.45s17.61-8.72,17.61-19.45V197.56a24.23,24.23,0,0,0,9.58-12.85l2.49-7.61,39.06,16.1a7.09,7.09,0,0,0,2.77.55,7.83,7.83,0,0,0,7.29-5.54l1.11-3.42a29.79,29.79,0,0,0,20.55-5.1,36.42,36.42,0,0,0,15-24.18,40,40,0,0,0-1.43-19l28-16.16A8.62,8.62,0,0,0,359.34,109.3ZM97.67,271.58V199.2h4.54v72.38ZM122,241a14.83,14.83,0,0,1-4.38,10.71V219.12A14.83,14.83,0,0,1,122,229.83Zm94.23,16.7c0,1.52-1,2.8-2.19,2.8s-2.19-1.28-2.19-2.8v-2.81h4.38Zm0-19.46H137.6v-5.6h78.65Zm0-22.25h-4.38V199.48l.3.12a19.89,19.89,0,0,0,4.08,1.19Zm10.45-36.78a8,8,0,0,1-4.06,4.81,6.14,6.14,0,0,1-5,.09l-9.31-3.82c-3.81-1.58-5.74-6.46-4.3-10.87l2.49-7.61,22.78,9.4Zm54.24-4.55L160.56,125l1.7-5.19,71.61,29.56a7.41,7.41,0,0,0,8.17-1.83l12.59-13.31,34.83,14.37Zm32.54-18.9c-2.29,7-7.82,11.73-14.1,12.54l6.13-18.77,8.54-4.95A21.67,21.67,0,0,1,313.48,155.71Zm-14.76-21.18L255.53,116.7a7.33,7.33,0,0,0-8.16,1.83l-12.6,13.32L139.51,92.54l17.05-52.15L333.9,114.21Z"
      />
    </svg>
  );
};

export default AccessControl;
