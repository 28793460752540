//@flow
import React, { useState } from "react";
import Animate from "animate.css-react";
import { NavLink } from "react-router-dom";

import TincLogo from "../../assets/images/shared/tinc-logo.png";
import TincLogoWhite from "../../assets/images/shared/tinc-logo-white.png";

import "animate.css/animate.css";

type HeaderProps = {
  hasOverlay: Boolean,
  overlayBlue: Boolean,
};

const Header = (props: HeaderProps) => {
  const [isNavOpen, toggleNav] = useState(false);

  return (
    <div
      className={`Header ${props.hasOverlay &&
        "Header--hasOverlay "} ${props.overlayBlue && "Header--overlayBlue"}`}
    >
      <div className="Header__inner">
        <div className="Header__logo">
          <NavLink to="/" exact>
            {!props.hasOverlay && !props.overlayBlue ? (
              <img src={TincLogo} alt="Tinc Logo" />
            ) : (
              <img src={TincLogoWhite} alt="Tinc Logo" />
            )}
          </NavLink>
        </div>

        {/* for small screens */}
        <div className="Header__links--small">
          <i className="fas fa-bars" onClick={() => toggleNav(!isNavOpen)}></i>
        </div>

        {isNavOpen && (
          <Animate
            enter="bounceIn"
            leave="bounceOut"
            appear="bounceIn"
            durationAppear={1000}
            component="div"
          >
            <div className="Header__small">
              <div className="Header__small__heading">
                <NavLink to="/" exact>
                  <img src={TincLogo} alt="Tinc Logo" />
                </NavLink>

                <i
                  className="fab fa-mixer"
                  onClick={() => toggleNav(!isNavOpen)}
                ></i>
              </div>
              <div className="Header__small__inner">
                <NavLink
                  to="/"
                  exact
                  className="Header__small__link"
                  activeClassName="Header__small__link--active"
                >
                  Home
                </NavLink>

                <div className="Header__small__sub">
                  <div className="Header__small__sub--heading">Services</div>

                  <NavLink
                    to="/services"
                    className="Header__small__link"
                    activeClassName="Header__small__link--active"
                  >
                    <span>Overview</span>
                  </NavLink>

                  <NavLink
                    to="/consultancy"
                    className="Header__small__link"
                    activeClassName="Header__small__link--active"
                  >
                    <span>IT Consultancy</span>
                  </NavLink>

                  <NavLink
                    to="/procurement"
                    className="Header__small__link"
                    activeClassName="Header__small__link--active"
                  >
                    <span>IT procurement</span>
                  </NavLink>

                  <NavLink
                    to="/repair"
                    className="Header__small__link"
                    activeClassName="Header__small__link--active"
                  >
                    <span>Managed IT Services</span>
                  </NavLink>
                </div>

                <NavLink
                  to="/projects"
                  className="Header__small__link"
                  activeClassName="Header__small__link--active"
                >
                  Projects
                </NavLink>

                <NavLink
                  to="/contact"
                  className="Header__small__link"
                  activeClassName="Header__small__link--active"
                >
                  Contact Us
                </NavLink>

                <a
                  href="https://support.tinc.com.ng"
                  className="Header__small__link__btn"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Tinc Support
                </a>
              </div>
            </div>
          </Animate>
        )}

        {/* for large screens */}
        <div className="Header__links">
          <NavLink
            to="/"
            exact
            className="Header__link"
            activeClassName="Header__link--active"
          >
            Home
          </NavLink>

          <div className="Header__link__services">
            <NavLink
              to="/services"
              className="Header__link"
              activeClassName="Header__link--active"
            >
              Services
              <i className="fas fa-caret-down"></i>
            </NavLink>

            <div className="Header__link__subMenu">
              <NavLink
                to="/consultancy"
                className="Header__link__subMenu__item"
                activeClassName="Header__link__subMenu__item--active"
              >
                <span>IT Consultancy</span>
                <i className="fas fa-angle-right"></i>
              </NavLink>

              <NavLink
                to="/procurement"
                className="Header__link__subMenu__item"
                activeClassName="Header__link__subMenu__item--active"
              >
                <span>IT procurement</span>
                <i className="fas fa-angle-right"></i>
              </NavLink>

              <NavLink
                to="/repair"
                className="Header__link__subMenu__item"
                activeClassName="Header__link__subMenu__item--active"
              >
                <span>Managed IT Services</span>
                <i className="fas fa-angle-right"></i>
              </NavLink>
            </div>
          </div>

          <NavLink
            to="/projects"
            className="Header__link"
            activeClassName="Header__link--active"
          >
            Projects
          </NavLink>

          <NavLink
            to="/contact"
            className="Header__link"
            activeClassName="Header__link--active"
          >
            Contact Us
          </NavLink>

          <a
            href="https://support.tinc.com.ng"
            className="Header__link__btn"
            target="_blank"
            rel="noopener noreferrer"
          >
            Tinc Support
          </a>
        </div>
      </div>
    </div>
  );
};

Header.defaultProps = {
  hasOverlay: false,
  overlayBlue: false,
};

export default Header;
