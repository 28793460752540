import * as types from '../types/alert';
import { toastifyTypes, IAction } from '../../Types/general.d';
import { RootState } from '../index';

export const addAlert =
  (type: toastifyTypes, message: string) =>
  async (
    dispatch: (e: IAction) => void,
    getState: () => RootState
  ): Promise<void> => {
    dispatch({ type: types.ALERT_ADD, payload: { type, message } });
  };

export const removeAlert =
  () =>
  async (
    dispatch: (e: IAction) => void,
    getState: () => RootState
  ): Promise<void> => {
    dispatch({ type: types.ALERT_REMOVE });
  };
