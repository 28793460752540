//@flow
import React from "react";

type SoftwareProps = {
  color?: string,
};

const Software = (props: SoftwareProps) => {
  return (
    <svg id="Layer_1" data-name="Layer 1" viewBox="0 0 566.93 566.93">
      <g id="Outline">
        <path
          className="cls-1"
          fill={props.color === "white" ? "#fff" : "#6d55ff"}
          d="M538.56,0H28.37A28.34,28.34,0,0,0,0,28.37V538.56A28.34,28.34,0,0,0,28.37,566.9H538.56a28.34,28.34,0,0,0,28.34-28.34V28.37A28.34,28.34,0,0,0,538.56,0ZM548,538.56a9.44,9.44,0,0,1-9.44,9.44H28.37a9.44,9.44,0,0,1-9.44-9.44v-444H548ZM18.93,75.61V28.37a9.44,9.44,0,0,1,9.44-9.44H538.56A9.44,9.44,0,0,1,548,28.37V75.61Z"
        />
        <path
          className="cls-2"
          fill={props.color === "white" ? "#fff" : "#6d55ff"}
          d="M40.56,40.56a9.76,9.76,0,0,0-2.74,6.71,9.47,9.47,0,0,0,.76,3.59,8.83,8.83,0,0,0,2,3.12A9.49,9.49,0,0,0,54,40.56,9.91,9.91,0,0,0,40.56,40.56Z"
        />
        <path
          className="cls-3"
          fill={props.color === "white" ? "#fff" : "#6d55ff"}
          d="M78.35,40.56a10,10,0,0,0-2.74,6.71,13.45,13.45,0,0,0,.19,1.89,6.08,6.08,0,0,0,.57,1.7,7.31,7.31,0,0,0,.85,1.7A15.64,15.64,0,0,0,78.35,54a9.59,9.59,0,0,0,13.42,0l1.13-1.42a6.94,6.94,0,0,0,.85-1.7,5.78,5.78,0,0,0,.57-1.7,13.45,13.45,0,0,0,.19-1.89,9.45,9.45,0,0,0-16.16-6.71Z"
        />
        <path
          className="cls-4"
          fill={props.color === "white" ? "#fff" : "#6d55ff"}
          d="M116.14,40.56a9.49,9.49,0,1,0,13.42,0A9.91,9.91,0,0,0,116.14,40.56Z"
        />
        <path
          className="cls-5"
          fill={props.color === "white" ? "#fff" : "#6d55ff"}
          d="M529.11,113.4H37.82V245.67H529.11Zm-18.9,113.38H56.72V132.3H510.21Z"
        />
        <path
          className="cls-6"
          fill={props.color === "white" ? "#fff" : "#6d55ff"}
          d="M207.88,264.57H37.82V529.11H207.88ZM189,510.21H56.72V283.46H189Z"
        />
        <path
          className="cls-7"
          fill={props.color === "white" ? "#fff" : "#6d55ff"}
          d="M226.78,387.39H529.11V264.57H226.78Zm18.89-103.93H510.21v85H245.67Z"
        />
        <path
          className="cls-8"
          fill={props.color === "white" ? "#fff" : "#6d55ff"}
          d="M226.78,529.11H529.11V406.29H226.78Zm18.89-103.93H510.21v85H245.67Z"
        />
      </g>
    </svg>
  );
};

export default Software;
