//@flow
import React from "react";

type InfrastructureProps = {
  color?: string,
};

const Infrastructure = (props: InfrastructureProps) => {
  return (
    <svg id="Layer_1" data-name="Layer 1" viewBox="0 0 566.93 566.93">
      <path
        className="cls-1"
        fill={props.color === "white" ? "#fff" : "#6d55ff"}
        d="M534.93,54.86a32,32,0,0,0-32,32v86.87A18.31,18.31,0,0,1,484.63,192H447a63.93,63.93,0,0,0-88.8-92,73.3,73.3,0,0,0-56.49-26.87,72.57,72.57,0,0,0-26.58,5.08,64.39,64.39,0,0,0-55.71-32.51,63.15,63.15,0,0,0-16.68,2.22A82.29,82.29,0,0,0,45.72,82.3a83.29,83.29,0,0,0,.62,9.87,54.83,54.83,0,0,0,8.52,109H351.53a101.07,101.07,0,0,0-41.79,35.35,45.65,45.65,0,0,0-35.42-17.06H91.44a45.77,45.77,0,0,0-45.72,45.72v256a45.77,45.77,0,0,0,45.72,45.72H274.32A45.77,45.77,0,0,0,320,521.21V389.15A45.34,45.34,0,0,0,338.32,393h0V530.35a36.42,36.42,0,0,0,64,23.94,36.42,36.42,0,0,0,64-23.94V284.25a13.72,13.72,0,0,1,11.26-13.5l29.25-5.32a73.09,73.09,0,0,0,60.08-72V86.87A32,32,0,0,0,534.93,54.86Zm-480.07,128A36.58,36.58,0,1,1,91.44,146.3h18.29A54.93,54.93,0,0,0,64.9,92.4,63.09,63.09,0,0,1,64,82.3a64,64,0,0,1,124.79-20l3.14,9.55,9.22-4A45.24,45.24,0,0,1,219.46,64a46,46,0,0,1,39.65,23A73.08,73.08,0,0,0,228.6,146.3h18.29a54.79,54.79,0,0,1,99.74-31.39,63.61,63.61,0,0,0,3.25,68ZM64,265.18a27.47,27.47,0,0,1,27.43-27.44H274.32a27.42,27.42,0,0,1,25.52,17.61,100.18,100.18,0,0,0-7.23,37.26v36.57H64Zm237.74,256a27.47,27.47,0,0,1-27.43,27.43H91.44A27.47,27.47,0,0,1,64,521.21v-64H301.75Zm0-82.3H64V347.47H292.63a45.14,45.14,0,0,0,9.12,27ZM402.34,100.58a45.72,45.72,0,1,1-45.72,45.72A45.77,45.77,0,0,1,402.34,100.58Zm146.3,92.88a54.85,54.85,0,0,1-45.06,54l-29.25,5.31a32,32,0,0,0-26.27,31.49v246.1a18.29,18.29,0,0,1-36.58,0V356.62H393.19V530.35a18.29,18.29,0,1,1-36.57,0V265.18H338.33V374.74a27.45,27.45,0,0,1-27.43-27.43v-54.7a82.36,82.36,0,0,1,71.64-81.53l39.48-.77h62.61a36.61,36.61,0,0,0,36.58-36.57V86.87a13.72,13.72,0,1,1,27.43,0Z"
      />
      <path
        className="cls-2"
        fill={props.color === "white" ? "#fff" : "#6d55ff"}
        d="M109.73,310.9A27.44,27.44,0,1,0,82.3,283.47,27.47,27.47,0,0,0,109.73,310.9Zm0-36.58a9.15,9.15,0,1,1-9.15,9.15A9.15,9.15,0,0,1,109.73,274.32Z"
      />
      <path
        className="cls-3"
        fill={props.color === "white" ? "#fff" : "#6d55ff"}
        d="M265.18,256h18.28V310.9H265.18Z"
      />
      <path
        className="cls-4"
        fill={props.color === "white" ? "#fff" : "#6d55ff"}
        d="M228.6,256h18.29V310.9H228.6Z"
      />
      <path
        className="cls-5"
        fill={props.color === "white" ? "#fff" : "#6d55ff"}
        d="M192,256h18.29V310.9H192Z"
      />
      <path
        className="cls-6"
        fill={props.color === "white" ? "#fff" : "#6d55ff"}
        d="M256,420.63a27.44,27.44,0,1,0-27.43-27.44A27.46,27.46,0,0,0,256,420.63Zm0-36.58a9.15,9.15,0,1,1-9.14,9.14A9.15,9.15,0,0,1,256,384.05Z"
      />
      <path
        className="cls-7"
        fill={props.color === "white" ? "#fff" : "#6d55ff"}
        d="M82.3,365.76h18.28v54.87H82.3Z"
      />
      <path
        className="cls-8"
        fill={props.color === "white" ? "#fff" : "#6d55ff"}
        d="M118.87,365.76h18.29v54.87H118.87Z"
      />
      <path
        className="cls-9"
        fill={props.color === "white" ? "#fff" : "#6d55ff"}
        d="M155.45,365.76h18.29v54.87H155.45Z"
      />
      <path
        className="cls-10"
        fill={props.color === "white" ? "#fff" : "#6d55ff"}
        d="M109.73,475.49a27.43,27.43,0,1,0,27.43,27.43A27.46,27.46,0,0,0,109.73,475.49Zm0,36.58a9.15,9.15,0,1,1,9.14-9.15A9.15,9.15,0,0,1,109.73,512.07Z"
      />
      <path
        className="cls-11"
        fill={props.color === "white" ? "#fff" : "#6d55ff"}
        d="M265.18,475.49h18.28v54.86H265.18Z"
      />
      <path
        className="cls-12"
        fill={props.color === "white" ? "#fff" : "#6d55ff"}
        d="M228.6,475.49h18.29v54.86H228.6Z"
      />
      <path
        className="cls-13"
        fill={props.color === "white" ? "#fff" : "#6d55ff"}
        d="M192,475.49h18.29v54.86H192Z"
      />
    </svg>
  );
};

export default Infrastructure;
