//@flow
import React from "react";

type ConferencingProps = {
  color?: string,
};

const Conferencing = (props: ConferencingProps) => {
  return (
    <svg id="Layer_1" data-name="Layer 1" viewBox="0 0 566.93 566.93">
      <path
        className="cls-1"
        fill={props.color === "white" ? "#fff" : "#6d55ff"}
        d="M550.93,94.7a54.32,54.32,0,0,0-38.58-15.94H402.93a10.23,10.23,0,1,0,0,20.46H512.35a34,34,0,0,1,34.06,33.92v280H20.52v-280A34,34,0,0,1,54.58,99.22H163.25a10.23,10.23,0,1,0,0-20.46H54.58A54.54,54.54,0,0,0,0,133.14V423.38a54.53,54.53,0,0,0,54.58,54.37H178.5v39.17l-25.58,29.55h-9.67a10.23,10.23,0,1,0,0,20.46H422.92a10.23,10.23,0,1,0,0-20.46H414l-25.58-29.55V477.75H512.35a54.53,54.53,0,0,0,54.58-54.37V133.14A53.92,53.92,0,0,0,550.93,94.7ZM512.35,457.3H270.41a10.23,10.23,0,1,0,0,20.45H367.9v32.74H349.3a10.23,10.23,0,1,0,0,20.46h24.17l13.43,15.52H180L193.47,531h24.17a10.23,10.23,0,1,0,0-20.46H199V477.75h36.13a10.23,10.23,0,1,0,0-20.45H54.58a33.9,33.9,0,0,1-32.47-23.69H544.82A33.89,33.89,0,0,1,512.35,457.3Z"
      />
      <path
        className="cls-2"
        fill={props.color === "white" ? "#fff" : "#6d55ff"}
        d="M380.37,184.77V96.92C380.37,43.47,336.72,0,283.08,0S185.8,43.47,185.8,96.92v.53a10.27,10.27,0,0,0,20.53,0v-.53c0-42.17,34.43-76.46,76.75-76.46s76.76,34.29,76.76,76.46v87.85c0,42.16-34.43,76.45-76.76,76.45s-76.75-34.29-76.75-76.45V132.56a10.27,10.27,0,0,0-20.53,0v52.21a97,97,0,0,0,64.73,91.33v28H238.76a31.18,31.18,0,0,0-31.2,31.09v2.13H207a10.23,10.23,0,1,0,0,20.45H360.44a10.23,10.23,0,1,0,0-20.45h-2.22v-2.13A31.18,31.18,0,0,0,327,304.15H315.25V276.24A96.9,96.9,0,0,0,380.37,184.77ZM271.06,304.15V280.94a98.69,98.69,0,0,0,12,.74h0a96.37,96.37,0,0,0,11.62-.7v23.17Zm56,20.46a10.67,10.67,0,0,1,10.68,10.63v2.13H228.08v-2.13a10.67,10.67,0,0,1,10.68-10.63Z"
      />
      <path
        className="cls-3"
        fill={props.color === "white" ? "#fff" : "#6d55ff"}
        d="M282.88,45.22a52.69,52.69,0,0,0-52.72,52.56v46.55a52.76,52.76,0,0,0,105.51,0V97.78A52.72,52.72,0,0,0,282.88,45.22Zm0,131.21a32.09,32.09,0,0,1-31.15-23.79,52.78,52.78,0,0,0,62.31,0A32.07,32.07,0,0,1,282.91,176.43Zm32.19-66.18a32.21,32.21,0,0,1-64.42,0V97.78a32.21,32.21,0,0,1,64.42,0Z"
      />
      <path
        className="cls-4"
        fill={props.color === "white" ? "#fff" : "#6d55ff"}
        d="M293.15,97.45v13.11a10.26,10.26,0,0,1-20.52,0V97.45a10.26,10.26,0,0,1,20.52,0Z"
      />
      <path
        className="cls-5"
        fill={props.color === "white" ? "#fff" : "#6d55ff"}
        d="M496.7,346.72v31a20.49,20.49,0,0,1-20.5,20.43h-18a10.23,10.23,0,1,1,0-20.46h18V346.72l-87.4,0a10.23,10.23,0,1,1,0-20.46H476.2A20.49,20.49,0,0,1,496.7,346.72Z"
      />
      <path
        className="cls-6"
        fill={props.color === "white" ? "#fff" : "#6d55ff"}
        d="M433.18,387.9a10.26,10.26,0,0,1-10.26,10.23H89.58a20.49,20.49,0,0,1-20.5-20.43v-31a20.49,20.49,0,0,1,20.5-20.42h88.53a10.23,10.23,0,1,1,0,20.46H89.58l0,30.94,333.31,0A10.26,10.26,0,0,1,433.18,387.9Z"
      />
      <path
        className="cls-7"
        fill={props.color === "white" ? "#fff" : "#6d55ff"}
        d="M293.34,227.76v3.48a10.26,10.26,0,0,1-20.51,0v-3.48a10.26,10.26,0,0,1,20.51,0Z"
      />
      <path
        className="cls-8"
        fill={props.color === "white" ? "#fff" : "#6d55ff"}
        d="M210.65,271.45a10.26,10.26,0,0,1-10.26,10.23h-107a24.27,24.27,0,0,1-24.29-24.19V186.31a24.28,24.28,0,0,1,24.29-24.19H163a10.23,10.23,0,1,1,0,20.46H93.37a3.79,3.79,0,0,0-2.66,1.1,3.69,3.69,0,0,0-1.1,2.63v71.18a3.75,3.75,0,0,0,3.76,3.73h107A10.26,10.26,0,0,1,210.65,271.45Z"
      />
      <path
        className="cls-9"
        fill={props.color === "white" ? "#fff" : "#6d55ff"}
        d="M496.7,186.31v71.18a24.27,24.27,0,0,1-24.28,24.19H365.78a10.23,10.23,0,1,1,0-20.46H472.42a3.74,3.74,0,0,0,3.75-3.73V186.31a3.75,3.75,0,0,0-3.75-3.73H403.2a10.23,10.23,0,1,1,0-20.46h69.22A24.27,24.27,0,0,1,496.7,186.31Z"
      />
    </svg>
  );
};

export default Conferencing;
