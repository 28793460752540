//@flow
import React from "react";

type PeripheralsProps = {
  color?: string,
};

const Peripherals = (props: PeripheralsProps) => {
  return (
    <svg id="Layer_1" data-name="Layer 1" viewBox="0 0 566.93 566.93">
      <path
        className="cls-1"
        fill={props.color === "white" ? "#fff" : "#6d55ff"}
        d="M521.21,18.29H455.52A27.4,27.4,0,0,0,429.77,0H137.16a27.39,27.39,0,0,0-25.75,18.29H45.72A45.77,45.77,0,0,0,0,64V356.62a45.77,45.77,0,0,0,45.72,45.72H128V566.93H360.4l78.51-78.51V402.34h82.3a45.77,45.77,0,0,0,45.72-45.72V64A45.77,45.77,0,0,0,521.21,18.29ZM128,27.43a9.14,9.14,0,0,1,9.14-9.14H429.77a9.14,9.14,0,0,1,9.14,9.14V192a9.15,9.15,0,0,1-9.14,9.15h-64V192a27.47,27.47,0,0,0-27.43-27.43H228.6A27.47,27.47,0,0,0,201.17,192v9.15h-64A9.15,9.15,0,0,1,128,192ZM347.47,201.17h-128V192a9.14,9.14,0,0,1,9.14-9.14H338.33a9.14,9.14,0,0,1,9.14,9.14ZM45.72,36.58h64V192a27.47,27.47,0,0,0,27.43,27.44H429.77A27.47,27.47,0,0,0,457.2,192V36.58h64A27.46,27.46,0,0,1,548.64,64V274.32H18.29V64A27.46,27.46,0,0,1,45.72,36.58Zm429.77,292.6h-384V292.61H475.49ZM18.29,356.62v-64H73.15v91.44H45.72A27.47,27.47,0,0,1,18.29,356.62Zm73.15,27.43V347.47H128v36.58Zm54.86-36.58H420.62v128H347.47v73.15H146.3ZM407.7,493.78l-41.94,41.93V493.78Zm31.21-146.31h36.58v36.58H438.91Zm82.3,36.58H493.78V292.61h54.86v64A27.47,27.47,0,0,1,521.21,384.05Z"
      />
      <path
        className="cls-2"
        fill={props.color === "white" ? "#fff" : "#6d55ff"}
        d="M91.44,54.86H36.58v54.87H91.44ZM73.15,91.44H54.86V73.15H73.15Z"
      />
      <path
        className="cls-3"
        fill={props.color === "white" ? "#fff" : "#6d55ff"}
        d="M91.44,128H36.58v54.86H91.44ZM73.15,164.59H54.86V146.3H73.15Z"
      />
      <path
        className="cls-4"
        fill={props.color === "white" ? "#fff" : "#6d55ff"}
        d="M164.59,365.76h18.29v18.29H164.59Z"
      />
      <path
        className="cls-5"
        fill={props.color === "white" ? "#fff" : "#6d55ff"}
        d="M201.17,365.76H402.34v18.29H201.17Z"
      />
      <path
        className="cls-6"
        fill={props.color === "white" ? "#fff" : "#6d55ff"}
        d="M164.59,402.34H402.34v18.28H164.59Z"
      />
      <path
        className="cls-7"
        fill={props.color === "white" ? "#fff" : "#6d55ff"}
        d="M164.59,438.91v91.44H329.18V438.91ZM310.9,512.07h-128V457.2h128Z"
      />
      <path
        className="cls-8"
        fill={props.color === "white" ? "#fff" : "#6d55ff"}
        d="M384.05,438.91h18.29V457.2H384.05Z"
      />
      <path
        className="cls-9"
        fill={props.color === "white" ? "#fff" : "#6d55ff"}
        d="M347.47,438.91h18.29V457.2H347.47Z"
      />
      <path
        className="cls-10"
        fill={props.color === "white" ? "#fff" : "#6d55ff"}
        d="M402.34,36.58h18.28V54.86H402.34Z"
      />
      <path
        className="cls-11"
        fill={props.color === "white" ? "#fff" : "#6d55ff"}
        d="M365.76,36.58h18.29V54.86H365.76Z"
      />
      <path
        className="cls-12"
        fill={props.color === "white" ? "#fff" : "#6d55ff"}
        d="M329.18,36.58h18.29V54.86H329.18Z"
      />
      <path
        className="cls-13"
        fill={props.color === "white" ? "#fff" : "#6d55ff"}
        d="M512.07,237.74h18.28V256H512.07Z"
      />
      <path
        className="cls-14"
        fill={props.color === "white" ? "#fff" : "#6d55ff"}
        d="M512.07,201.17h18.28v18.29H512.07Z"
      />
      <path
        className="cls-15"
        fill={props.color === "white" ? "#fff" : "#6d55ff"}
        d="M512.07,164.59h18.28v18.29H512.07Z"
      />
    </svg>
  );
};

export default Peripherals;
