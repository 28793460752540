//@flow
import React from "react";

type ScalableProps = {
  color?: string,
};

const Scalable = (props: ScalableProps) => {
  return (
    <svg id="Layer_1" data-name="Layer 1" viewBox="0 0 566.93 566.93">
      <g id="Data_backup" data-name="Data backup">
        <path
          className="cls-1"
          fill={props.color === "white" ? "#fff" : "#6d55ff"}
          d="M561.58,385A18.78,18.78,0,0,0,546.84,378h-7.11v-18.9a28.34,28.34,0,0,0-28.35-28.34H435.79a28.35,28.35,0,0,0-28.35-28.35H369.65a28.35,28.35,0,0,0-28.35,28.35h-9.45a28.34,28.34,0,0,0-28.34,28.34V378H286.94a18.9,18.9,0,0,0-18.45,23l32,143.78a28.18,28.18,0,0,0,27.62,22.2H505.71a28.18,28.18,0,0,0,27.67-22.2l32-143.78A18.77,18.77,0,0,0,561.58,385Zm-239.17-26a9.44,9.44,0,0,1,9.44-9.44h18.9a9.45,9.45,0,0,0,9.45-9.45v-9.45a9.45,9.45,0,0,1,9.45-9.45h37.79a9.45,9.45,0,0,1,9.45,9.45v9.45a9.45,9.45,0,0,0,9.45,9.45h85a9.45,9.45,0,0,1,9.45,9.44V378H322.41ZM514.9,540.63a9.41,9.41,0,0,1-9.19,7.4H328.07a9.39,9.39,0,0,1-9.22-7.4L286.94,396.85h259.9Z"
        />
        <path
          className="cls-2"
          fill={props.color === "white" ? "#fff" : "#6d55ff"}
          d="M492.48,311.81a9.45,9.45,0,0,1-7.69-4l-47.24-66.14a9.45,9.45,0,0,1,7.69-14.94h18.9V179.53H350.75a9.45,9.45,0,0,1-9.45-9.45v-37.8a9.45,9.45,0,0,1,9.45-9.45H473.59a47.31,47.31,0,0,1,47.24,47.25v56.69h18.9a9.45,9.45,0,0,1,7.69,14.94l-47.24,66.14A9.45,9.45,0,0,1,492.48,311.81ZM463.6,245.67l28.88,40.43,28.89-40.43h-10a9.45,9.45,0,0,1-9.45-9.45V170.08a28.34,28.34,0,0,0-28.34-28.35H360.2v18.9H464.14a18.9,18.9,0,0,1,18.9,18.9v56.69a9.45,9.45,0,0,1-9.45,9.45Z"
        />
        <path
          className="cls-3"
          fill={props.color === "white" ? "#fff" : "#6d55ff"}
          d="M256.26,538.58H114.53a47.29,47.29,0,0,1-47.24-47.24V434.65H48.39a9.45,9.45,0,0,1-7.69-14.94l47.24-66.14a9.8,9.8,0,0,1,13.76-1.63,9.67,9.67,0,0,1,1.63,1.63l47.24,66.14a9.45,9.45,0,0,1-7.69,14.94H124v47.24H256.26a9.45,9.45,0,0,1,9.45,9.45v37.79A9.45,9.45,0,0,1,256.26,538.58ZM66.75,415.75h10a9.46,9.46,0,0,1,9.45,9.45v66.14a28.34,28.34,0,0,0,28.34,28.34H246.82V500.79H124a18.9,18.9,0,0,1-18.9-18.9V425.2a9.45,9.45,0,0,1,9.45-9.45h10L95.63,375.32Z"
        />
        <path
          className="cls-4"
          fill={props.color === "white" ? "#fff" : "#6d55ff"}
          d="M294.06,94.49a28.35,28.35,0,0,0,28.35-28.35V28.35A28.35,28.35,0,0,0,294.06,0H29.49A28.34,28.34,0,0,0,1.15,28.35V66.14A28.34,28.34,0,0,0,29.49,94.49h9.45v9.45H29.49A28.34,28.34,0,0,0,1.15,132.28v37.8a28.35,28.35,0,0,0,28.34,28.35h9.45v9.44H29.49A28.35,28.35,0,0,0,1.15,236.22V274a28.34,28.34,0,0,0,28.34,28.34h9.45v18.9a9.45,9.45,0,0,0,9.45,9.45H275.16a9.45,9.45,0,0,0,9.45-9.45v-18.9h9.45A28.35,28.35,0,0,0,322.41,274v-37.8a28.36,28.36,0,0,0-28.35-28.35h-9.45v-9.44h9.45a28.36,28.36,0,0,0,28.35-28.35v-37.8a28.35,28.35,0,0,0-28.35-28.34h-9.45V94.49ZM20,66.14V28.35a9.45,9.45,0,0,1,9.45-9.45H294.06a9.45,9.45,0,0,1,9.45,9.45V66.14a9.45,9.45,0,0,1-9.45,9.45H29.49A9.45,9.45,0,0,1,20,66.14ZM265.71,311.81H57.84v-9.45H265.71Zm37.8-75.59V274a9.45,9.45,0,0,1-9.45,9.44H29.49A9.45,9.45,0,0,1,20,274v-37.8a9.45,9.45,0,0,1,9.45-9.45H294.06A9.45,9.45,0,0,1,303.51,236.22Zm-37.8-28.35H57.84v-9.44H265.71Zm37.8-75.59v37.8a9.45,9.45,0,0,1-9.45,9.45H29.49A9.45,9.45,0,0,1,20,170.08v-37.8a9.45,9.45,0,0,1,9.45-9.45H294.06A9.45,9.45,0,0,1,303.51,132.28Zm-37.8-28.34H57.84V94.49H265.71Z"
        />
        <path
          className="cls-5"
          fill={props.color === "white" ? "#fff" : "#6d55ff"}
          d="M38.94,28.35h18.9V66.14H38.94Z"
        />
        <path
          className="cls-6"
          fill={props.color === "white" ? "#fff" : "#6d55ff"}
          d="M76.74,28.35H95.63V66.14H76.74Z"
        />
        <path
          className="cls-7"
          fill={props.color === "white" ? "#fff" : "#6d55ff"}
          d="M114.53,28.35h18.9V66.14h-18.9Z"
        />
        <path
          className="cls-8"
          fill={props.color === "white" ? "#fff" : "#6d55ff"}
          d="M152.33,28.35h18.89V66.14H152.33Z"
        />
        <path
          className="cls-9"
          fill={props.color === "white" ? "#fff" : "#6d55ff"}
          d="M38.94,132.28h18.9v37.8H38.94Z"
        />
        <path
          className="cls-10"
          fill={props.color === "white" ? "#fff" : "#6d55ff"}
          d="M76.74,132.28H95.63v37.8H76.74Z"
        />
        <path
          className="cls-11"
          fill={props.color === "white" ? "#fff" : "#6d55ff"}
          d="M114.53,132.28h18.9v37.8h-18.9Z"
        />
        <path
          className="cls-12"
          fill={props.color === "white" ? "#fff" : "#6d55ff"}
          d="M152.33,132.28h18.89v37.8H152.33Z"
        />
        <path
          className="cls-13"
          fill={props.color === "white" ? "#fff" : "#6d55ff"}
          d="M38.94,236.22h18.9V274H38.94Z"
        />
        <path
          className="cls-14"
          fill={props.color === "white" ? "#fff" : "#6d55ff"}
          d="M76.74,236.22H95.63V274H76.74Z"
        />
        <path
          className="cls-15"
          fill={props.color === "white" ? "#fff" : "#6d55ff"}
          d="M114.53,236.22h18.9V274h-18.9Z"
        />
        <path
          className="cls-16"
          fill={props.color === "white" ? "#fff" : "#6d55ff"}
          d="M152.33,236.22h18.89V274H152.33Z"
        />
        <path
          className="cls-17"
          fill={props.color === "white" ? "#fff" : "#6d55ff"}
          d="M227.92,37.8h18.9V56.69h-18.9Z"
        />
        <path
          className="cls-18"
          fill={props.color === "white" ? "#fff" : "#6d55ff"}
          d="M265.71,37.8h18.9V56.69h-18.9Z"
        />
        <path
          className="cls-19"
          fill={props.color === "white" ? "#fff" : "#6d55ff"}
          d="M227.92,141.73h18.9v18.9h-18.9Z"
        />
        <path
          className="cls-20"
          fill={props.color === "white" ? "#fff" : "#6d55ff"}
          d="M265.71,141.73h18.9v18.9h-18.9Z"
        />
        <path
          className="cls-21"
          fill={props.color === "white" ? "#fff" : "#6d55ff"}
          d="M227.92,245.67h18.9v18.9h-18.9Z"
        />
        <path
          className="cls-22"
          fill={props.color === "white" ? "#fff" : "#6d55ff"}
          d="M265.71,245.67h18.9v18.9h-18.9Z"
        />
      </g>
    </svg>
  );
};

export default Scalable;
