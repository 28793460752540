import React, { Component } from "react";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";

import { fetchOneProject } from "../../Store/actions";
import { RootState } from "../../Store";
import { baseImageUrl } from "../../shared/config";

import Header from "../../components/Header";
import Footer from "../../components/Footer";
import RequestQuote from "../../components/RequestQuote";
import ExpandImg from "../../components/ExpandImg";

class ProjectOne extends Component {
  state = {
    expandedImgOpen: false,
    imgToExpand: "",
  };

  async componentDidMount() {
    const { match, fetchOneProject } = this.props;
    window.scrollTo(0, 0);
    await fetchOneProject(match.params.projectId);
    //if (history.action === "POP") history.push("/projects");
  }

  toggleExpandedImg = (state) => {
    this.setState({ expandedImgOpen: state });
  };

  setImageToExpand = (img) => {
    this.setState({ imgToExpand: img });
  };

  render() {
    const { selectedProject } = this.props;
    const { expandedImgOpen, imgToExpand } = this.state;
    return (
      <div className="ProjectLayout">
        {selectedProject && (
          <Helmet>
            <meta
              name="title"
              content={`Tinc - ${
                selectedProject && selectedProject.name
              } Project`}
            />
            <meta
              property="og:title"
              content={`Tinc - ${
                selectedProject && selectedProject.name
              } Project`}
            />
            <meta
              property="og:url"
              content={`https://tinc.com.ng/project/${
                selectedProject && selectedProject._id
              }`}
            />
            <link
              rel="canonical"
              href={`https://tinc.com.ng/project/${
                selectedProject && selectedProject._id
              }`}
            />
            <title>
              Tinc - {selectedProject && selectedProject.name} Project{" "}
            </title>
          </Helmet>
        )}
        <Header />
        <div className="ProjectLayout__header">
          <img
            src={
              selectedProject &&
              selectedProject.gallery &&
              selectedProject.gallery.length > 0
                ? baseImageUrl + selectedProject.gallery[0].image
                : ""
            }
            alt="Header icon"
          />
          <div className="ProjectLayout__header__overlay">
            <div className="ProjectLayout__header__overlay__content">
              <p>{selectedProject && selectedProject.name}</p>
              <h4>{selectedProject && selectedProject.description}</h4>
            </div>
          </div>
        </div>

        <div className="ProjectLayout__content">
          <h4>Case Study</h4>
          <br />
          <div className="ProjectLayout__content__inner">
            <div className="row">
              <div className="col-md-6">
                <h2>Background</h2>
                <span
                  dangerouslySetInnerHTML={{
                    __html: selectedProject && selectedProject.background,
                  }}
                ></span>
              </div>
              <div className="col-md-6">
                <h2>Our Solution</h2>
                <span
                  dangerouslySetInnerHTML={{
                    __html: selectedProject && selectedProject.solutions,
                  }}
                ></span>
              </div>
            </div>
          </div>
        </div>

        <div className="ProjectLayout__gallery">
          <div className="ProjectLayout__gallery__inner">
            <div className="row">
              {selectedProject &&
                selectedProject.gallery &&
                selectedProject.gallery.length > 0 &&
                selectedProject.gallery.map((item, idx: number) => (
                  <div
                    key={idx}
                    className="col-md-3 ProjectLayout__gallery__item"
                  >
                    <img
                      src={baseImageUrl + item.image}
                      onClick={() => {
                        this.setImageToExpand(baseImageUrl + item.image);
                        this.toggleExpandedImg(!expandedImgOpen);
                      }}
                      alt="project img "
                    />
                  </div>
                ))}
            </div>
          </div>
        </div>
        <RequestQuote />
        <Footer color="white" />
        <ExpandImg
          isOpen={expandedImgOpen}
          onClose={() => this.toggleExpandedImg(!expandedImgOpen)}
          image={imgToExpand}
        />
      </div>
    );
  }
}

const mapStateToProps = ({ project }: RootState) => {
  return {
    loading: project.loading,
    selectedProject: project.selectedProject,
  };
};

export default connect(mapStateToProps, { fetchOneProject })(ProjectOne);
