//@flow
import React from "react";

type MobilityProps = {
  color?: string,
};

const Mobility = (props: MobilityProps) => {
  return (
    <svg id="Layer_1" data-name="Layer 1" viewBox="0 0 566.93 566.93">
      <path
        className="cls-1"
        fill={props.color === "white" ? "#fff" : "#6d55ff"}
        d="M330.71,28.35H236.22a9.45,9.45,0,1,0,0,18.89h94.49a9.45,9.45,0,1,0,0-18.89Z"
      />
      <path
        className="cls-2"
        fill={props.color === "white" ? "#fff" : "#6d55ff"}
        d="M396.85,28.35H387.4a9.45,9.45,0,1,0,0,18.89h9.45a9.45,9.45,0,1,0,0-18.89Z"
      />
      <path
        className="cls-3"
        fill={props.color === "white" ? "#fff" : "#6d55ff"}
        d="M295.2,481.89H271.74A26.11,26.11,0,0,0,245.67,508v4.57a26.1,26.1,0,0,0,26.06,26.06h23.46a26.1,26.1,0,0,0,26.07-26.06V508A26.1,26.1,0,0,0,295.2,481.89Zm7.16,30.64a7.17,7.17,0,0,1-7.16,7.16H271.74a7.17,7.17,0,0,1-7.17-7.16V508a7.17,7.17,0,0,1,7.17-7.17h23.45a7.17,7.17,0,0,1,7.17,7.17Z"
      />
      <path
        className="cls-4"
        fill={props.color === "white" ? "#fff" : "#6d55ff"}
        d="M410,0H157a34.21,34.21,0,0,0-34.16,34.16V532.78A34.2,34.2,0,0,0,157,566.93h253a34.19,34.19,0,0,0,34.15-34.14V34.16A34.19,34.19,0,0,0,410,0ZM425.2,532.78A15.27,15.27,0,0,1,410,548H157a15.27,15.27,0,0,1-15.26-15.25V34.16A15.28,15.28,0,0,1,157,18.9h253A15.28,15.28,0,0,1,425.2,34.16V532.78Z"
      />
      <path
        className="cls-5"
        fill={props.color === "white" ? "#fff" : "#6d55ff"}
        d="M434.65,56.69H132.28a9.46,9.46,0,0,0-9.45,9.45V463a9.46,9.46,0,0,0,9.45,9.45H434.65A9.46,9.46,0,0,0,444.1,463V66.14A9.46,9.46,0,0,0,434.65,56.69ZM425.2,453.55H141.73v-378H425.2Z"
      />
    </svg>
  );
};

export default Mobility;
