//@flow
import React, { Component } from "react";

import { checkValidity } from "../../shared/validations";

import Input from "../../components/Form/Input";
import Textarea from "../../components/Form/TextArea";
import Button from "../../components/Form/Button";
import Arrow from "../../components/Icons/Arrow";

type RequestQuoteProps = {};

type RequestQuoteState = {
  requestQuoteForm: {
    email: {
      value: string,
      valid: boolean,
      focused: boolean,
      messageClassName: string,
    },
    message: {
      value: string,
      valid: boolean,
      focused: boolean,
      messageClassName: string,
    },
  },
  formIsValid: boolean,
};

class RequestQuote extends Component<RequestQuoteProps, RequestQuoteState> {
  state = {
    requestQuoteForm: {
      email: {
        value: "",
        valid: false,
        focused: false,
        messageClassName: "none",
      },
      message: {
        value: "",
        valid: false,
        focused: false,
        messageClassName: "none",
      },
    },
    formIsValid: false,
  };

  handleInputOnChange = (
    event: Object,
    elementId: string,
    validations: Object
  ) => {
    const value = event.target.value;

    const updatedFormElement = {
      ...this.state.requestQuoteForm[elementId],
      value,
      valid: checkValidity(value, validations),
      messageClassName:
        !checkValidity(value, validations) && value !== ""
          ? "input__message--error"
          : "none",
    };

    const updatedForm = {
      ...this.state.requestQuoteForm,
      [elementId]: updatedFormElement,
    };

    let formIsValid = true;
    for (let elementId in updatedForm) {
      formIsValid = updatedForm[elementId].valid && formIsValid;
    }

    this.setState({
      requestQuoteForm: updatedForm,
      formIsValid: formIsValid,
    });
  };

  handleInputFocus = (name: string, updatedState: Object) => {
    const updatedNameObject = {
      ...this.state.requestQuoteForm[name],
      ...updatedState,
    };

    const updatedForm = {
      ...this.state.requestQuoteForm,
      [name]: updatedNameObject,
    };

    this.setState({
      requestQuoteForm: updatedForm,
    });
  };

  encode = (data: Object) => {
    return Object.keys(data)
      .map(
        (key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key])
      )
      .join("&");
  };

  handleOnRequestQuote = (e: Object) => {
    e.preventDefault();
    const form = e.target;
    const requestQuoteForm = this.state.requestQuoteForm;

    if (
      requestQuoteForm.email.valid &&
      requestQuoteForm.email.value !== "" &&
      requestQuoteForm.message.valid &&
      requestQuoteForm.message.value !== ""
    ) {
      fetch("/", {
        method: "POST",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: this.encode({
          "form-name": form.getAttribute("name"),
          email: requestQuoteForm.email.value,
          message: requestQuoteForm.message.value,
        }),
      })
        .then(() =>
          window.alert(
            "We have successfully captured your request for a quote and would get back to you in 24 hours!"
          )
        )
        .catch((error) =>
          alert("An error occured while capturing your request!")
        );
    } else {
      window.alert("Invalid form submission");
    }
  };

  render() {
    const requestQuoteForm = this.state.requestQuoteForm;

    return (
      <div className="RequestQuote">
        <div className="RequestQuote__inner">
          <div className="RequestQuote__header">
            <h2>Have a Project In Mind?</h2>
            <p>
              Request for a quote and we will get back to you as soon as
              tommorow.
            </p>
          </div>

          <div className="RequestQuote__body">
            <form
              name="Request-Quote"
              data-netlify="true"
              data-netlify-honeypot="bot-field"
              onSubmit={this.handleOnRequestQuote}
            >
              <input type="hidden" name="form-name" value="Request-Quote" />
              <Input
                attributes={{
                  type: "email",
                  name: "email",
                  placeholder: "Enter your email",
                  required: true,
                  theme: "white",
                  value: requestQuoteForm.email.value,
                  onChange: (event) =>
                    this.handleInputOnChange(event, "email", {
                      required: true,
                      isEmail: true,
                    }),
                  onFocus: () =>
                    this.handleInputFocus("email", {
                      focused: true,
                    }),
                  onBlur: () =>
                    this.handleInputFocus("email", {
                      focused: false,
                    }),
                }}
                hasError={!requestQuoteForm.email.valid}
                focused={requestQuoteForm.email.focused}
                message={
                  !requestQuoteForm.email.valid &&
                  !requestQuoteForm.email.focused &&
                  requestQuoteForm.email.value !== ""
                    ? "Your email is required and must be valid"
                    : ""
                }
                messageClassName={requestQuoteForm.email.messageClassName}
              />

              <Textarea
                attributes={{
                  type: "text",
                  name: "message",
                  placeholder: "Type your message",
                  required: true,
                  theme: "white",
                  value: requestQuoteForm.message.value,
                  onChange: (event) =>
                    this.handleInputOnChange(event, "message", {
                      required: true,
                    }),
                  onFocus: () =>
                    this.handleInputFocus("message", {
                      focused: true,
                    }),
                  onBlur: () =>
                    this.handleInputFocus("message", {
                      focused: false,
                    }),
                }}
                hasError={!requestQuoteForm.message.valid}
                focused={requestQuoteForm.message.focused}
                message={
                  !requestQuoteForm.message.valid &&
                  !requestQuoteForm.message.focused &&
                  requestQuoteForm.message.value !== ""
                    ? "The message is required!"
                    : ""
                }
                messageClassName={requestQuoteForm.message.messageClassName}
              />

              <div className="RequestQuote__body__btn">
                <Button color="brand-white" disabled={!this.state.formIsValid}>
                  Request Quote <Arrow color="dark" />
                </Button>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

export default RequestQuote;
