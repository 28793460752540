import axios from '../../shared/axios';

import * as types from '../../Store/types/project';
import { addAlert } from '.';
import { IAction, IProject } from '../../Types/general.d';
import { RootState } from '../index';

export const fetchProjects =
  () =>
  async (
    dispatch: (e: IAction) => void,
    getState: () => RootState
  ): Promise<IProject[]> => {
    let data;
    try {
      dispatch({ type: types.PROJECT_INIT });

      const projects = await axios.get(`/project/v1/projects`);
      data = projects.data.data;

      dispatch({ type: types.FETCH_PROJECTS, payload: data });
    } catch (err) {
      const errData = err.response.data;
      dispatch({ type: types.PROJECT_FAIL });
      dispatch(
        //@ts-expect-error mismatch
        addAlert(
          'error',
          errData.data.errors && errData.data.errors.length > 0
            ? errData.data.errors[0].msg
            : errData.message
        )
      );
    } finally {
      // eslint-disable-next-line no-unsafe-finally
      return data;
    }
  };

  export const fetchOneProject =
  (projectId: string) =>
  async (
    dispatch: (e: IAction) => void,
    getState: () => RootState
  ): Promise<IProject> => {
    let data;
    try {
      dispatch({ type: types.PROJECT_INIT });

      const projects = await axios.get(`/project/v1/project/${projectId}`);
      data = projects.data.data;

      dispatch({ type: types.FETCH_ONE_PROJECT, payload: data });
    } catch (err) {
      const errData = err.response.data;
      dispatch({ type: types.PROJECT_FAIL });
      dispatch(
        //@ts-expect-error mismatch
        addAlert(
          'error',
          errData.data.errors && errData.data.errors.length > 0
            ? errData.data.errors[0].msg
            : errData.message
        )
      );
    } finally {
      // eslint-disable-next-line no-unsafe-finally
      return data;
    }
  };


export const selectProject =
  (project: IProject) =>
  (
    dispatch: (e: IAction) => void,
    getState: () => RootState
  ): void => {
    dispatch({ type: types.SELECT_PROJECT, payload: project });
  };
