//@flow
import React from "react";

type LifestyleMobilityProps = {
  color?: string,
};

const LifestyleMobility = (props: LifestyleMobilityProps) => {
  return (
    <svg id="Layer_1" data-name="Layer 1" viewBox="0 0 566.93 566.93">
      <g id="Smartphone-Control-Home-Car-Camera">
        <path
          className="cls-1"
          fill={props.color === "white" ? "#fff" : "#6d55ff"}
          d="M283.49,179.6H189a47.29,47.29,0,0,0-47.23,47.23v85.78A66.17,66.17,0,0,0,85.12,378V491.33a9.83,9.83,0,0,0,.28,2.29l9.17,36.66v27.17A9.45,9.45,0,0,0,104,566.9H217.37a9.45,9.45,0,0,0,9.44-9.45V541.39l17.31-25.95a9.51,9.51,0,0,0,1.59-5.22V489.35a46.71,46.71,0,0,1,.66-7.47h37.12a47.29,47.29,0,0,0,47.23-47.23V226.83A47.29,47.29,0,0,0,283.49,179.6ZM226.81,489.35v18l-17.3,26a9.45,9.45,0,0,0-1.59,5.21V548H113.46v-18.9a9.83,9.83,0,0,0-.28-2.29L104,490.17V378a47.31,47.31,0,0,1,37.79-46.28v74.62a9.38,9.38,0,0,0,1.35,4.87l22.94,38.24A28.43,28.43,0,0,0,190,463.12a28.81,28.81,0,0,0,27.59-20.91l10.89-40.62a9.44,9.44,0,0,1,18.39,4.31q-.06.28-.15.57l-17.63,65.79A66.16,66.16,0,0,0,226.81,489.35Zm85-54.7A28.34,28.34,0,0,1,283.49,463H251.1l13.84-51.65a28.36,28.36,0,1,0-54.79-14.67l-10.89,40.62a9.34,9.34,0,0,1-9,6.91h-.13a9.2,9.2,0,0,1-7.87-4.53l-21.56-36V226.83A28.33,28.33,0,0,1,189,198.5h94.46a28.33,28.33,0,0,1,28.34,28.33Z"
        />
        <rect
          className="cls-2"
          fill={props.color === "white" ? "#fff" : "#6d55ff"}
          x="226.81"
          y="217.39"
          width="56.68"
          height="18.89"
        />
        <rect
          className="cls-3"
          fill={props.color === "white" ? "#fff" : "#6d55ff"}
          x="189.03"
          y="217.39"
          width="18.89"
          height="18.89"
        />
        <rect
          className="cls-4"
          fill={props.color === "white" ? "#fff" : "#6d55ff"}
          x="226.81"
          y="340.19"
          width="18.89"
          height="18.89"
        />
        <path
          className="cls-5"
          fill={props.color === "white" ? "#fff" : "#6d55ff"}
          d="M202.87,316.23l13.35,13.36a29,29,0,0,1,40.07,0l13.36-13.36A47.24,47.24,0,0,0,202.87,316.23Z"
        />
        <path
          className="cls-6"
          fill={props.color === "white" ? "#fff" : "#6d55ff"}
          d="M176.14,289.52l13.36,13.36a66.23,66.23,0,0,1,93.52,0l13.36-13.36A85.12,85.12,0,0,0,176.14,289.52Z"
        />
        <path
          className="cls-7"
          fill={props.color === "white" ? "#fff" : "#6d55ff"}
          d="M102.67,132.37h40.47a37.6,37.6,0,0,0,72.86.1h20.17a9.45,9.45,0,0,0,9.44-9.45l.1-56.68a9.43,9.43,0,0,0-3.78-7.55L166.44,2.11A9.41,9.41,0,0,0,160.77.22L9.64,0h0A9.45,9.45,0,0,0,.19,9.48L0,122.83a9.45,9.45,0,0,0,9.45,9.45H29.76a37.6,37.6,0,0,0,72.91.09Zm76.91,9.45a18.9,18.9,0,1,1,18.89-18.89A18.89,18.89,0,0,1,179.58,141.82Zm36.47-28.25a37.6,37.6,0,0,0-72.91-.09H132.35V19.07h19l-.1,47.24a9.45,9.45,0,0,0,9.45,9.44h66.12l0,37.79Zm-45.86-85L208,56.9H170.14ZM113.46,19V56.8H19l.05-37.87ZM29.81,113.39H18.92L19,75.69h94.46v37.79H102.67a37.59,37.59,0,0,0-72.86-.09Zm36.42,28.43a18.9,18.9,0,1,1,18.89-18.89A18.89,18.89,0,0,1,66.23,141.82Z"
        />
        <path
          className="cls-8"
          fill={props.color === "white" ? "#fff" : "#6d55ff"}
          d="M463,37.91a37.79,37.79,0,1,0,37.78,37.78A37.79,37.79,0,0,0,463,37.91Zm0,56.68a18.9,18.9,0,1,1,18.89-18.9A18.89,18.89,0,0,1,463,94.59Z"
        />
        <path
          className="cls-9"
          fill={props.color === "white" ? "#fff" : "#6d55ff"}
          d="M548,28.46H524.7L508.64,4.33a9.43,9.43,0,0,0-7.89-4.2H425.18a9.43,9.43,0,0,0-7.86,4.2L401.24,28.46H378a18.9,18.9,0,0,0-18.89,18.9v85A18.89,18.89,0,0,0,378,151.26H548a18.89,18.89,0,0,0,18.9-18.89v-85A18.9,18.9,0,0,0,548,28.46Zm0,103.91H378v-85h28.34a9.44,9.44,0,0,0,7.86-4.21L430.24,19H495.7l16.06,24.13a9.44,9.44,0,0,0,7.89,4.21H548Z"
        />
        <path
          className="cls-10"
          fill={props.color === "white" ? "#fff" : "#6d55ff"}
          d="M537.59,402.09a9.42,9.42,0,0,0-8.5-5.22H415.74a9.42,9.42,0,0,0-8.5,5.22L378.9,458.77a9.45,9.45,0,0,0,4.22,12.67,9.64,9.64,0,0,0,4.28,1h9.45v85a9.44,9.44,0,0,0,9.44,9.45H538.54a9.44,9.44,0,0,0,9.44-9.45v-85h9.45a9.46,9.46,0,0,0,9.5-9.4,9.31,9.31,0,0,0-1-4.27Zm-116,13.67H523.25l18.9,37.78H402.68ZM481.86,548H463V510.22h18.89Zm47.23,0H500.75V500.77a9.44,9.44,0,0,0-9.44-9.44H453.52a9.44,9.44,0,0,0-9.44,9.44V548H415.74V472.44H529.09Z"
        />
        <path
          className="cls-11"
          fill={props.color === "white" ? "#fff" : "#6d55ff"}
          d="M403.52,252.41l66.13-66.13-13.36-13.36-63.36,63.36H349.61v18.89h47.24A9.43,9.43,0,0,0,403.52,252.41Z"
        />
        <path
          className="cls-12"
          fill={props.color === "white" ? "#fff" : "#6d55ff"}
          d="M456.29,375.21l13.36-13.36-66.13-66.12a9.4,9.4,0,0,0-6.67-2.77H349.61v18.89h43.32Z"
        />
        <path
          className="cls-13"
          fill={props.color === "white" ? "#fff" : "#6d55ff"}
          d="M122.9,283.51V264.62H94.57v-85H75.67v94.47a9.45,9.45,0,0,0,9.45,9.44Z"
        />
      </g>
    </svg>
  );
};

export default LifestyleMobility;
