import React, { Component } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";

import Header from "../../components/Header";
import Footer from "../../components/Footer";
import Button from "../../components/Form/Button";
import Arrow from "../../components/Icons/Arrow";

import HeaderBg from "../../assets/images/services/header.png";
import ItConsultancy from "../../assets/images/services/it-consultancy.svg";
import ItProcurement from "../../assets/images/services/it-procurement.png";
import ItSupport from "../../assets/images/services/it-support.png";
import HardwareRepair from "../../assets/images/services/hardware-repair.svg";
import ProjectImg from "../../assets/images/services/projects.png";

class Services extends Component {

  componentDidMount() {
    window.scrollTo(0, 0)
  }
  
  render() {
    return (
      <div className="Services">
        <Helmet>
          <meta name="title" content="Tinc - Services" />
          <meta property="og:title" content="Tinc - Services" />
          <meta property="og:url" content="https://tinc.com.ng/services" />
          <link rel="canonical" href="https://tinc.com.ng/services" />
          <title>Tinc - Services</title>
        </Helmet>
        <Header />
        <img
          src={HeaderBg}
          className="Services__header"
          alt="header background"
        />

        <div className="Services__title">
          We are leading the world at delivering these services
        </div>

        <section
          id="it-consultancy"
          className="Services__item Services__item__consultancy"
        >
          <img src={ItConsultancy} alt="IT Consultancy" />

          <div className="Services__item__content">
            <h4>IT Consultancy</h4>
            <br />
            <p>
              Tinc provides IT consultancy services and tailored technology
              solutions spanning Video conferncing, Back-up solutions, server
              room set-up, managed services to help organisations achieve their
              business goals and gain a competitive edge.
            </p>
            <br />
            <Link to="/consultancy">
              <Button>
                Learn More <Arrow color="black" />
              </Button>
            </Link>
          </div>
        </section>

        <section
          id="it-procurement"
          className="Services__item Services__item--reverse Services__item__procurement"
        >
          <div className="Services__item__content">
            <h4>IT Procurement</h4>
            <br />
            <p>
              With strong partnerships and with the leading technology vendors
              and a proactive, responsive and experienced team of IT
              specialists, Tinc aims to help organisations gain a competitive
              edge using technology that fits your business model.
            </p>
            <br />
            <Link to="/procurement">
              <Button>
                Get Started <Arrow color="black" />
              </Button>
            </Link>
          </div>

          <img src={ItProcurement} alt="IT Procurement" />
        </section>

        <section
          id="hardware-repair"
          className="Services__item Services__item__repair"
        >
          <img src={HardwareRepair} alt="Hardware Repair" />

          <div className="Services__item__content">
            <h4>Hardware Repair & Maintenance.</h4>
            <br />
            <p>
              Going beyond Deploying IT Hardware Solutions, our solutions
              involve worldclass after sales services that help the solutions we
              provide stand the test of time.
            </p>
            <br />
            <Link to="/repair">
              <Button>
                Learn More <Arrow color="black" />
              </Button>
            </Link>
          </div>
        </section>

        <section
          id="it-support"
          className="Services__item Services__item--reverse Services__item__support"
        >
          <div className="Services__item__content">
            <h4>Managed IT Services</h4>
            <br />
            <p>
              Tinc provides a full range of flexible IT support services that
              keep organizations operational and ensure systems are performing
              to their full potential.
            </p>
            <br />
            <Link to="/repair">
              <Button>
                Get Started <Arrow color="black" />
              </Button>
            </Link>
          </div>

          <img src={ItSupport} alt="IT Procurement" />
        </section>

        <section id="projects" className="Services__projects ">
          <div className="Services__projects__inner Services__item">
            <img src={ProjectImg} alt="Projects" />

            <div className="Services__item__content">
              <h4>
                We strongly believe that technology plays a vital role in any
                business whether small or big.
              </h4>
              <br />
              <p>
                Our goal is to help you use technology in ways that help you
                achieve your business goals efficiently.
              </p>
              <br />
              <Link to="/projects">
                <Button>
                  See Projects <Arrow color="black" />
                </Button>
              </Link>
            </div>
          </div>
        </section>
        <Footer />
      </div>
    );
  }
}

export default Services;
