import { createStore, applyMiddleware, compose, combineReducers } from 'redux';
import thunk from 'redux-thunk';
import { persistStore, persistReducer } from 'redux-persist';
import localforage from 'localforage';
//import storage from 'redux-persist/lib/storage';
import { environment } from '../shared/config';

//reducers
import alertReducer from './reducers/alert';
import projectReducer from './reducers/project';

//redux dev tools
const composeEnhancers =
  (environment === 'development'
    ? (window['__REDUX_DEVTOOLS_EXTENSION_COMPOSE__'])
    : null) || compose;

//combining all reducers
const rootReducer = combineReducers({
  alert: alertReducer,
  project: projectReducer,
});

const persistConfig = {
  key: 'BoddsWebsite',
  storage: localforage,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

//creating a redux store
const store = createStore(persistedReducer, composeEnhancers(applyMiddleware(thunk)));

export default store;

export const persistor = persistStore(store);

export type RootState = ReturnType<typeof rootReducer>;
