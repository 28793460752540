import React, { Component, Fragment } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { connect } from 'react-redux';

import { fetchProjects } from '../../Store/actions';
import { RootState } from '../../Store';
import { baseImageUrl } from '../../shared/config';

import Header from "../../components/Header";
import Footer from "../../components/Footer";
import RequestQuote from "../../components/RequestQuote";

import HeaderBg from "../../assets/images/projects/header.png";

class Projects extends Component {

  componentDidMount() {
    const { fetchProjects } = this.props;
    window.scrollTo(0, 0);
    fetchProjects();
  }

  render() {
    const { loading, projects } = this.props;
    return (
      <div className="Projects">
        <Helmet>
          <meta name="title" content="Tinc - Our Impact" />
          <meta property="og:title" content="Tinc - Our Impact" />
          <meta property="og:url" content="https://tinc.com.ng/projects" />
          <link rel="canonical" href="https://tinc.com.ng/projects" />
          <title>Tinc - Our Impact</title>
        </Helmet>
        <Header />
        <img
          src={HeaderBg}
          className="Projects__header"
          alt="header background"
        />

        <div className="Projects__title">
          Working with the best brands locally and across the globe.
        </div>

        <div className="Projects__group">
          <div className="row">
            {!loading && projects && projects.length > 0 && projects.map((project, idx) => (
              <Fragment key={idx}> 
                { idx % 2 === 0 ? (
                  <div key={project._id} className="col-md-4">
                    <div className="Projects__item ">
                      <Link to={`/project/${project._id}`}>
                        <img src={project.gallery.length > 0 ? baseImageUrl + project.gallery[0].image : ''} alt="Project Background" />
                        <div className="Projects__item--hover">
                          <div className="Projects__item--hover__content">
                            <small>{project.name}</small>
                            <br />
                            <h4>{project.description}</h4>
                          </div>
                        </div>
                      </Link>
                    </div>
                  </div>
                ) : (
                  <div key={project._id} className="col-md-8">
                    <div className="Projects__item ">
                      <Link to={`/project/${project._id}`}>
                        <img src={project.gallery.length > 0 ? baseImageUrl + project.gallery[0].image : ''} alt="Project Background" />
                        <div className="Projects__item--hover">
                          <div className="Projects__item--hover__content">
                            <small>{project.name}</small>
                            <br />
                            <h4>{project.description}</h4>
                          </div>
                        </div>
                      </Link>
                    </div>
                  </div>
                )}
              </ Fragment>
            ))}
          </div>
        </div>

        <RequestQuote  />
        <Footer color="white" />
      </div>
    );
  }
}

const mapStateToProps = ({ project }: RootState) => {
  return {
    loading: project.loading,
    projects: project.projects
  }
} 

export default connect(mapStateToProps, { fetchProjects })(Projects);
