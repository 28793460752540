//@flow
import React from "react";

type EnterpriseSolutionsProps = {
  color?: string,
};

const EnterpriseSolutions = (props: EnterpriseSolutionsProps) => {
  return (
    <svg id="Layer_1" data-name="Layer 1" viewBox="0 0 566.93 566.93">
      <g id="Network-Connection-Router-Computer-Router">
        <path
          className="cls-1"
          fill={props.color === "white" ? "#fff" : "#6d55ff"}
          d="M538.58,378H406.3A28.35,28.35,0,0,0,378,406.3v94.49a28.34,28.34,0,0,0,28.35,28.34H432L427.28,548h-21v18.9H538.58V548h-21l-4.73-18.9h25.7a28.34,28.34,0,0,0,28.35-28.34h0V406.3A28.35,28.35,0,0,0,538.58,378ZM406.3,396.85H538.58A9.45,9.45,0,0,1,548,406.3v66.14H396.85V406.3A9.45,9.45,0,0,1,406.3,396.85ZM498.14,548h-51.4l4.72-18.9h42Zm40.44-37.79H406.3a9.45,9.45,0,0,1-9.45-9.45v-9.45H548v9.45A9.45,9.45,0,0,1,538.58,510.24Z"
        />
        <path
          className="cls-2"
          fill={props.color === "white" ? "#fff" : "#6d55ff"}
          d="M538.58,0H406.3A28.35,28.35,0,0,0,378,28.35v94.48a28.35,28.35,0,0,0,28.35,28.35H432l-4.72,18.9h-21V189H538.58v-18.9h-21l-4.73-18.9h25.7a28.35,28.35,0,0,0,28.35-28.35V28.35A28.35,28.35,0,0,0,538.58,0ZM406.3,18.9H538.58A9.45,9.45,0,0,1,548,28.35V94.49H396.85V28.35A9.45,9.45,0,0,1,406.3,18.9Zm91.84,151.18h-51.4l4.72-18.9h42Zm40.44-37.8H406.3a9.45,9.45,0,0,1-9.45-9.45v-9.44H548v9.44A9.45,9.45,0,0,1,538.58,132.28Z"
        />
        <path
          className="cls-3"
          fill={props.color === "white" ? "#fff" : "#6d55ff"}
          d="M160.63,378H28.35A28.35,28.35,0,0,0,0,406.3v94.49a28.34,28.34,0,0,0,28.35,28.34H54L49.31,548h-21v18.9H160.63V548h-21l-4.72-18.9h25.69A28.34,28.34,0,0,0,189,500.79h0V406.3A28.35,28.35,0,0,0,160.63,378ZM28.35,396.85H160.63a9.45,9.45,0,0,1,9.45,9.45v66.14H18.9V406.3A9.45,9.45,0,0,1,28.35,396.85ZM120.18,548H68.8l4.72-18.9h41.94Zm40.45-37.79H28.35a9.45,9.45,0,0,1-9.45-9.45v-9.45H170.08v9.45A9.45,9.45,0,0,1,160.63,510.24Z"
        />
        <path
          className="cls-4"
          fill={props.color === "white" ? "#fff" : "#6d55ff"}
          d="M28.35,151.18H54l-4.73,18.9h-21V189H160.63v-18.9h-21l-4.72-18.9h25.69A28.35,28.35,0,0,0,189,122.83V28.35A28.35,28.35,0,0,0,160.63,0H28.35A28.35,28.35,0,0,0,0,28.35v94.48A28.35,28.35,0,0,0,28.35,151.18Zm91.83,18.9H68.8l4.72-18.9h41.94Zm40.45-37.8H28.35a9.45,9.45,0,0,1-9.45-9.45v-9.44H170.08v9.44A9.45,9.45,0,0,1,160.63,132.28ZM28.35,18.9H160.63a9.45,9.45,0,0,1,9.45,9.45V94.49H18.9V28.35A9.45,9.45,0,0,1,28.35,18.9Z"
        />
        <path
          className="cls-5"
          fill={props.color === "white" ? "#fff" : "#6d55ff"}
          d="M274,111.65v30.08h18.89V111.65a28.34,28.34,0,0,0,17.16-17.16h49V75.59h-49a28.17,28.17,0,0,0-53.21,0h-49v18.9h49A28.34,28.34,0,0,0,274,111.65Zm9.44-36.06A9.45,9.45,0,1,1,274,85,9.45,9.45,0,0,1,283.46,75.59Z"
        />
        <path
          className="cls-6"
          fill={props.color === "white" ? "#fff" : "#6d55ff"}
          d="M85,359.06v-49a28.34,28.34,0,0,0,17.16-17.16h30.08V274H102.2A28.34,28.34,0,0,0,85,256.86v-49H66.14v49a28.17,28.17,0,0,0,0,53.21v49Zm-9.45-85a9.45,9.45,0,1,1-9.45,9.44A9.45,9.45,0,0,1,75.59,274Z"
        />
        <path
          className="cls-7"
          fill={props.color === "white" ? "#fff" : "#6d55ff"}
          d="M481.89,207.87v49A28.34,28.34,0,0,0,464.73,274H434.65v18.89h30.08a28.34,28.34,0,0,0,17.16,17.16v49h18.9v-49a28.17,28.17,0,0,0,0-53.21v-49Zm9.45,85a9.45,9.45,0,1,1,9.45-9.45A9.45,9.45,0,0,1,491.34,292.91Z"
        />
        <path
          className="cls-8"
          fill={props.color === "white" ? "#fff" : "#6d55ff"}
          d="M292.91,455.28V425.2H274v30.08a28.34,28.34,0,0,0-17.16,17.16h-49v18.9h49a28.17,28.17,0,0,0,53.21,0h49v-18.9h-49A28.34,28.34,0,0,0,292.91,455.28Zm-9.45,36.06a9.45,9.45,0,1,1,9.45-9.45A9.45,9.45,0,0,1,283.46,491.34Z"
        />
        <rect
          className="cls-9"
          fill={props.color === "white" ? "#fff" : "#6d55ff"}
          x="340.16"
          y="302.36"
          width="18.9"
          height="18.9"
        />
        <rect
          className="cls-10"
          fill={props.color === "white" ? "#fff" : "#6d55ff"}
          x="302.36"
          y="302.36"
          width="18.9"
          height="18.9"
        />
        <rect
          className="cls-11"
          fill={props.color === "white" ? "#fff" : "#6d55ff"}
          x="207.87"
          y="302.36"
          width="18.9"
          height="18.9"
        />
        <path
          className="cls-12"
          fill={props.color === "white" ? "#fff" : "#6d55ff"}
          d="M396.85,264.57h-1.13l-7.37-59.06A18.9,18.9,0,0,0,369.64,189h-2.27a18.9,18.9,0,0,0-18.71,16.53l-7.37,59.06H225.64l-7.37-59.06A18.9,18.9,0,0,0,199.56,189h-2.27a18.9,18.9,0,0,0-18.71,16.53l-7.37,59.06h-1.13a19,19,0,0,0-18.9,18.89v56.7a19,19,0,0,0,18.9,18.9H396.85a19,19,0,0,0,18.9-18.9v-56.7A19,19,0,0,0,396.85,264.57Zm-29.48-56.7h2.27l7.08,56.7H360.28Zm-170.08,0h2.27l7.09,56.7H190.2ZM396.85,340.16H170.08v-56.7H396.85Z"
        />
        <path
          className="cls-13"
          fill={props.color === "white" ? "#fff" : "#6d55ff"}
          d="M81.13,71.68,94.49,85l13.36-13.36A18.89,18.89,0,0,0,81.13,71.68Z"
        />
        <path
          className="cls-14"
          fill={props.color === "white" ? "#fff" : "#6d55ff"}
          d="M114.54,65,127.9,51.64a47.31,47.31,0,0,0-66.82,0L74.44,65a28.34,28.34,0,0,1,40.09,0Z"
        />
        <path
          className="cls-15"
          fill={props.color === "white" ? "#fff" : "#6d55ff"}
          d="M459.08,71.68,472.44,85,485.8,71.68A18.89,18.89,0,0,0,459.08,71.68Z"
        />
        <path
          className="cls-16"
          fill={props.color === "white" ? "#fff" : "#6d55ff"}
          d="M492.48,65l13.36-13.36a47.23,47.23,0,0,0-66.8,0L452.4,65A29,29,0,0,1,492.48,65Z"
        />
        <path
          className="cls-17"
          fill={props.color === "white" ? "#fff" : "#6d55ff"}
          d="M459.08,449.63,472.44,463l13.36-13.36A18.89,18.89,0,0,0,459.08,449.63Z"
        />
        <path
          className="cls-18"
          fill={props.color === "white" ? "#fff" : "#6d55ff"}
          d="M492.48,443l13.36-13.36a47.25,47.25,0,0,0-66.8,0L452.4,443A29,29,0,0,1,492.48,443Z"
        />
        <path
          className="cls-19"
          fill={props.color === "white" ? "#fff" : "#6d55ff"}
          d="M81.13,449.63,94.49,463l13.36-13.36A18.89,18.89,0,0,0,81.13,449.63Z"
        />
        <path
          className="cls-20"
          fill={props.color === "white" ? "#fff" : "#6d55ff"}
          d="M114.54,443l13.36-13.36a47.32,47.32,0,0,0-66.82,0L74.44,443a28.35,28.35,0,0,1,40.09,0Z"
        />
      </g>
    </svg>
  );
};

export default EnterpriseSolutions;
