import React, { Component } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";

import Header from "../../components/Header";
import Footer from "../../components/Footer";
import Button from "../../components/Form/Button";
import Arrow from "../../components/Icons/Arrow";
import Scalable from "../../components/Icons/Scalable";
import Conferencing from "../../components/Icons/Conferencing";
import ServerRoom from "../../components/Icons/ServerRoom";
import CustomPc from "../../components/Icons/CustomPc";
import EnterpriseSolutions from "../../components/Icons/EnterpriseSolutions";
import AccessControl from "../../components/Icons/AccessControl";
import RequestQuote from "../../components/RequestQuote";

import HeaderDivider from "../../assets/images/itLayout/header-divider.png";
import HeaderBg from "../../assets/images/itLayout/consultancy-header-bg.png";
import HeadlineImg from "../../assets/images/itLayout/consultancy-headline.png";
import knowledgeImg from "../../assets/images/itLayout/knowledge.png";
import fastImg from "../../assets/images/itLayout/fast.png";
import budgetImg from "../../assets/images/itLayout/budget.png";
import salesImg from "../../assets/images/itLayout/sales.png";
import solutionsImg from "../../assets/images/itLayout/solutions.png";
import VendorImg from "../../assets/images/itLayout/vendor.png";
import InfoImg from "../../assets/images/itLayout/total-info.png";
import ClientEnsure from "../../assets/images/itLayout/client-ensure.png";
import ClientHp from "../../assets/images/itLayout/client-hp.png";
import ClientSamsung from "../../assets/images/itLayout/client-samsung.png";
import ClientTd from "../../assets/images/itLayout/client-td.png";
export class ItConsultancy extends Component {
  state = {
    solution: 0,
  };

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  handleOnChangeSolution = (solution) => {
    this.setState({ solution });
  };

  render() {
    return (
      <div className="ItLayout">
        <Helmet>
          <meta name="title" content="Tinc - IT Consultancy" />
          <meta property="og:title" content="Tinc - IT Consultancy" />
          <meta property="og:url" content="https://tinc.com.ng/consultancy" />
          <link rel="canonical" href="https://tinc.com.ng/consultancy" />
          <title>Tinc - IT Consultancy</title>
        </Helmet>
        <Header overlayBlue />

        <div className="ItLayout__header">
          <div className="ItLayout__header__content">
            <h1>IT Consultancy</h1>
            <br />
            <p>
              We supply IT products to thousands of customers, small,
              medium-sized and large businesses as well as the public sector.
            </p>
          </div>

          <img
            src={HeaderDivider}
            className="ItLayout__header__divider"
            alt="Header Divider"
          />
        </div>

        <div className="ItLayout__header__img">
          <img src={HeaderBg} alt="Header Background" />
        </div>

        <section id="headline" className="ItLayout__headline">
          <img src={HeadlineImg} alt="Headline" />

          <div className="ItLayout__headline__content">
            <h2>Tailored IT Solutions</h2>
            <br />
            <p>
              Tinc provides IT consultancy services and tailored technology
              solutions which include video conferencing, back-up solutions,
              server room set up and managed services to help organizations
              achieve their business goals and gain a competitive edge. <br />
              <br />
              We also provide complete IT solutions such as data centre setup
              and storage and other networking products, backed by the
              supporting services needed to implement and support your
              infrastructure securely - whether on the premise, in the cloud or
              a hybrid solution.
            </p>
          </div>
        </section>

        <section id="solutions" className="ItLayout__solutions">
          <div className="ItLayout__solutions__title">
            <h2>Our Solutions</h2>
          </div>

          <div className="row">
            <div className="col-md-4">
              <div
                className="ItLayout__solutions__card"
                onMouseOver={() => this.handleOnChangeSolution(1)}
                onMouseOut={() => this.handleOnChangeSolution(0)}
              >
                <div className="ItLayout__solutions__card__icon">
                  <Scalable
                    color={this.state.solution === 1 ? "white" : "blue"}
                  />
                </div>

                <h4>Scalable Backup Solution</h4>
                <br />
                <p>
                  Ranging from NAS (Network attached storage) infrastructures to
                  a variety of cloud back UPS storage services.
                </p>
              </div>
            </div>

            <div className="col-md-4">
              <div
                className="ItLayout__solutions__card"
                onMouseOver={() => this.handleOnChangeSolution(2)}
                onMouseOut={() => this.handleOnChangeSolution(0)}
              >
                <div className="ItLayout__solutions__card__icon">
                  <Conferencing
                    color={this.state.solution === 2 ? "white" : "blue"}
                  />
                </div>

                <h4>Video conferencing Solutions</h4>
                <br />
                <p>
                  In this age, people from all over the world connect to do
                  business. It is important that every business has a means of
                  communication with business partners, clients or prospecting
                  clients. We have a wide range of solutions to help achieve
                  this depending on the client’s requirement and budget.
                </p>
              </div>
            </div>

            <div className="col-md-4">
              <div
                className="ItLayout__solutions__card"
                onMouseOver={() => this.handleOnChangeSolution(3)}
                onMouseOut={() => this.handleOnChangeSolution(0)}
              >
                <div className="ItLayout__solutions__card__icon">
                  <ServerRoom
                    color={this.state.solution === 3 ? "white" : "blue"}
                  />
                </div>

                <h4>Server room set up and rack Installations.</h4>
                <br />
                <p>
                  We are able to help design your server room according to ISO
                  27001 an international standard covering security management
                  systems (SMS) including a risk assessment for physical
                  security including the design of server rooms and the security
                  of the data storage and also install processing assets within
                  the room.
                </p>
              </div>
            </div>

            <div className="col-md-4">
              <div
                className="ItLayout__solutions__card"
                onMouseOver={() => this.handleOnChangeSolution(4)}
                onMouseOut={() => this.handleOnChangeSolution(0)}
              >
                <div className="ItLayout__solutions__card__icon">
                  <AccessControl
                    color={this.state.solution === 4 ? "white" : "blue"}
                  />
                </div>

                <h4>Access Control and CCTV</h4>
                <br />
                <p>
                  We can provide and install security cameras (IP and Analogue),
                  access control (cards, fingerprint lock, iris scanner) for
                  your organization.
                </p>
              </div>
            </div>

            <div className="col-md-4">
              <div
                className="ItLayout__solutions__card"
                onMouseOver={() => this.handleOnChangeSolution(5)}
                onMouseOut={() => this.handleOnChangeSolution(0)}
              >
                <div className="ItLayout__solutions__card__icon">
                  <CustomPc
                    color={this.state.solution === 5 ? "white" : "blue"}
                  />
                </div>

                <h4>Custom PC Build</h4>
                <br />
                <p>
                  Partnering with PC part makers across the globe to build
                  custom PC's for 3D modeling, 3D rendering, virtualization,
                  sound production, video editing, game design and development,
                  gaming and 3D animation.
                </p>
              </div>
            </div>

            <div className="col-md-4">
              <div
                className="ItLayout__solutions__card"
                onMouseOver={() => this.handleOnChangeSolution(6)}
                onMouseOut={() => this.handleOnChangeSolution(0)}
              >
                <div className="ItLayout__solutions__card__icon">
                  <EnterpriseSolutions
                    color={this.state.solution === 6 ? "white" : "blue"}
                  />
                </div>

                <h4>Enterprise Equipment</h4>
                <br />
                <p>
                  We have a wide range of enterprise equipment’s such as
                  servers, checkpoints, access points, routers, extenders,
                  repeaters, surge protected PDU’s (power distribution unit)
                </p>
              </div>
            </div>
          </div>
        </section>

        <section id="whyus" className="ItLayout__whyus">
          <div className="ItLayout__whyus__inner">
            <div className="ItLayout__whyus__title">
              <h2>Why You Should Work With Us</h2>
            </div>

            <div className="row">
              <div className="col-md-4">
                <div className="ItLayout__whyus__card">
                  <div className="ItLayout__whyus__card__img">
                    <img src={knowledgeImg} alt="why us" />
                  </div>

                  <div className="ItLayout__whyus__card__content">
                    <h5>Knowledgeable & Experienced Team</h5>
                    <br />
                    <p>
                      We live and breathe technology. Our team have accrued
                      fantastic feedback for their knowledge and service levels.{" "}
                    </p>
                  </div>
                </div>
              </div>

              <div className="col-md-4">
                <div className="ItLayout__whyus__card">
                  <div className="ItLayout__whyus__card__img">
                    <img src={fastImg} alt="why us" />
                  </div>

                  <div className="ItLayout__whyus__card__content">
                    <h5>Fast Response Time</h5>
                    <br />
                    <p>
                      We offer the speed and flexibility that vendors and
                      distributors typically struggle to deliver.
                    </p>
                  </div>
                </div>
              </div>

              <div className="col-md-4">
                <div className="ItLayout__whyus__card">
                  <div className="ItLayout__whyus__card__img">
                    <img src={budgetImg} alt="why us" />
                  </div>

                  <div className="ItLayout__whyus__card__content">
                    <h5>Budget Flexibility</h5>
                    <br />
                    <p>
                      We would work with your budget to ensure you get the value
                      for your money
                    </p>
                  </div>
                </div>
              </div>

              <div className="col-md-4">
                <div className="ItLayout__whyus__card">
                  <div className="ItLayout__whyus__card__img">
                    <img src={salesImg} alt="why us" />
                  </div>

                  <div className="ItLayout__whyus__card__content">
                    <h5>After Sales Support</h5>
                    <br />
                    <p>
                      All products supply by tinc are supported by our team of
                      experts
                    </p>
                  </div>
                </div>
              </div>

              <div className="col-md-4">
                <div className="ItLayout__whyus__card">
                  <div className="ItLayout__whyus__card__img">
                    <img src={solutionsImg} alt="why us" />
                  </div>

                  <div className="ItLayout__whyus__card__content">
                    <h5>All Round Solutions</h5>
                    <br />
                    <p>
                      From product selection right through to configuration,
                      deployment, licensing and ongoing support, you are in safe
                      hands with Tinc
                    </p>
                  </div>
                </div>
              </div>

              <div className="col-md-4" style={{ marginLeft: "0" }}>
                <div className="ItLayout__whyus__card">
                  <div className="ItLayout__whyus__card__img">
                    <img src={VendorImg} alt="why us" />
                  </div>

                  <div className="ItLayout__whyus__card__content">
                    <h5>Vendor Agnostic Approach</h5>
                    <br />
                    <p>
                      We take a holistic, impartial approach focused on
                      identifying the right technology solutions and tailoring
                      them to meet your organizations need.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section id="info" className="ItLayout__info">
          <div className="ItLayout__info__inner">
            <div className="ItLayout__info__content">
              <h3>
                Tech solutions have enabled seamless inter-connectivity within
                organizations in Africa & around the globe.
              </h3>
              <br />
              <p>
                Learn about how we helped Total COOP base stations connect
                seamlessly accross Nigeria.
              </p>
              <br />
              <Link to="/project/totalcoop">
                <Button>
                  Learn More <Arrow color="dark" />
                </Button>
              </Link>
            </div>
            <div className="ItLayout__info__img">
              <img src={InfoImg} alt="info" style={{ marginRight: -140 }} />
            </div>
          </div>
        </section>

        <section id="clients" className="ItLayout__clients">
          <div className="ItLayout__clients__inner">
            <h3>Our Trusted Partners</h3>
            <div className="ItLayout__clients__items">
              <div className="ItLayout__clients__row">
                <div className="ItLayout__clients__item">
                  <img src={ClientHp} alt="Client Hp" />
                </div>

                <div className="ItLayout__clients__item">
                  <img
                    src={ClientTd}
                    alt="Client Td"
                    style={{ marginTop: "30px" }}
                  />
                </div>

                <div className="ItLayout__clients__item">
                  <img
                    src={ClientEnsure}
                    alt="Client Ensure"
                    style={{ marginTop: "60px" }}
                  />
                </div>

                <div className="ItLayout__clients__item">
                  <img
                    src={ClientSamsung}
                    alt="Client Samsung"
                    style={{ marginTop: "80px" }}
                  />
                </div>
              </div>
            </div>
          </div>
        </section>

        <RequestQuote />
        <Footer color="white" />
      </div>
    );
  }
}

export default ItConsultancy;
