import React, { Component } from "react";
import { Helmet } from "react-helmet";

import { checkValidity } from "../../shared/validations";

import Header from "../../components/Header";
import Footer from "../../components/Footer";
import Input from "../../components/Form/Input";
import Textarea from "../../components/Form/TextArea";
import Button from "../../components/Form/Button";
import Arrow from "../../components/Icons/Arrow";
import Call from "../../components/Icons/Call";
import Email from "../../components/Icons/Email";
import Support from "../../components/Icons/Support";

class Contact extends Component {
  state = {
    contactForm: {
      name: {
        value: "",
        valid: false,
        focused: false,
        messageClassName: "none",
      },
      email: {
        value: "",
        valid: false,
        focused: false,
        messageClassName: "none",
      },
      company: {
        value: "",
        valid: false,
        focused: false,
        messageClassName: "none",
      },
      country: {
        value: "",
        valid: false,
        focused: false,
        messageClassName: "none",
      },
      message: {
        value: "",
        valid: false,
        focused: false,
        messageClassName: "none",
      },
    },
    formIsValid: false,
  };

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  handleInputOnChange = (
    event: Object,
    elementId: string,
    validations: Object
  ) => {
    const value = event.target.value;

    const updatedFormElement = {
      ...this.state.contactForm[elementId],
      value,
      valid: checkValidity(value, validations),
      messageClassName:
        !checkValidity(value, validations) && value !== ""
          ? "input__message--error"
          : "none",
    };

    const updatedForm = {
      ...this.state.contactForm,
      [elementId]: updatedFormElement,
    };

    let formIsValid = true;
    for (let elementId in updatedForm) {
      formIsValid = updatedForm[elementId].valid && formIsValid;
    }

    this.setState({
      contactForm: updatedForm,
      formIsValid: formIsValid,
    });
  };

  handleInputFocus = (name: string, updatedState: Object) => {
    const updatedNameObject = {
      ...this.state.contactForm[name],
      ...updatedState,
    };

    const updatedForm = {
      ...this.state.contactForm,
      [name]: updatedNameObject,
    };

    this.setState({
      contactForm: updatedForm,
    });
  };

  encode = (data: Object) => {
    return Object.keys(data)
      .map(
        (key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key])
      )
      .join("&");
  };

  handleOnContact = (e: Object) => {
    e.preventDefault();
    const form = e.target;
    const contactForm = this.state.contactForm;

    if (
      contactForm.name.valid &&
      contactForm.name.value !== "" &&
      contactForm.email.valid &&
      contactForm.email.value !== "" &&
      contactForm.company.valid &&
      contactForm.company.value !== "" &&
      contactForm.country.valid &&
      contactForm.country.value !== "" &&
      contactForm.message.valid &&
      contactForm.message.value !== ""
    ) {
      fetch("/", {
        method: "POST",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: this.encode({
          "form-name": form.getAttribute("name"),
          "full name": contactForm.name.value,
          email: contactForm.email.value,
          company: contactForm.company.value,
          country: contactForm.country.value,
          message: contactForm.message.value,
        }),
      })
        .then(() =>
          window.alert(
            "You have successfully sent a contact request. We would get back to you as soon as possible!"
          )
        )
        .catch((error) =>
          alert("An error occured while sending your contact request!")
        );
    } else {
      window.alert("Invalid form submission");
    }
  };

  render() {
    const contactForm = this.state.contactForm;

    return (
      <div className="Contact">
        <Helmet>
          <meta name="title" content="Tinc - Contact Us" />
          <meta property="og:title" content="Tinc - Contact Us" />
          <meta property="og:url" content="https://tinc.com.ng/contact" />
          <link rel="canonical" href="https://tinc.com.ng/contact" />
          <title>Tinc - Contact Us</title>
        </Helmet>
        <Header overlayBlue />
        <div className="Contact__header">
          <div className="Contact__header__inner">
            <div className="Contact__header__content">
              <h1>Contact Our Support Team</h1>
              <p>Reach out to us and we will get back to you within 24 hours</p>
            </div>
          </div>
        </div>

        <div className="Contact__form">
          <div className="Contact__form__inner">
            <form
              name="contact"
              data-netlify="true"
              data-netlify-honeypot="bot-field"
              onSubmit={this.handleOnContact}
            >
              <input type="hidden" name="form-name" value="contact" />
              <div className="row">
                <div className="col-md-6">
                  <Input
                    label="Your name"
                    attributes={{
                      type: "text",
                      name: "full name",
                      placeholder: "Full Name",
                      required: true,
                      theme: "normal",
                      value: contactForm.name.value,
                      onChange: (event) =>
                        this.handleInputOnChange(event, "name", {
                          required: true,
                          isFullName: true,
                        }),
                      onFocus: () =>
                        this.handleInputFocus("name", {
                          focused: true,
                        }),
                      onBlur: () =>
                        this.handleInputFocus("name", {
                          focused: false,
                        }),
                    }}
                    hasError={!contactForm.name.valid}
                    focused={contactForm.name.focused}
                    message={
                      !contactForm.name.valid &&
                      !contactForm.name.focused &&
                      contactForm.name.value !== ""
                        ? "Your full name is required and must contain your first and last names to be valid"
                        : ""
                    }
                    messageClassName={contactForm.name.messageClassName}
                  />
                </div>
                <div className="col-md-6">
                  <Input
                    label="Your Email"
                    attributes={{
                      type: "email",
                      name: "email",
                      placeholder: "You@example.com",
                      required: true,
                      theme: "normal",
                      value: contactForm.email.value,
                      onChange: (event) =>
                        this.handleInputOnChange(event, "email", {
                          required: true,
                          isEmail: true,
                        }),
                      onFocus: () =>
                        this.handleInputFocus("email", {
                          focused: true,
                        }),
                      onBlur: () =>
                        this.handleInputFocus("email", {
                          focused: false,
                        }),
                    }}
                    hasError={!contactForm.email.valid}
                    focused={contactForm.email.focused}
                    message={
                      !contactForm.email.valid &&
                      !contactForm.email.focused &&
                      contactForm.email.value !== ""
                        ? "Your email is required and must be valid"
                        : ""
                    }
                    messageClassName={contactForm.email.messageClassName}
                  />
                </div>
                <div className="col-md-6">
                  <Input
                    label="Your Company"
                    attributes={{
                      type: "text",
                      name: "company",
                      placeholder: "Company Name",
                      required: true,
                      theme: "normal",
                      value: contactForm.company.value,
                      onChange: (event) =>
                        this.handleInputOnChange(event, "company", {
                          required: true,
                        }),
                      onFocus: () =>
                        this.handleInputFocus("company", {
                          focused: true,
                        }),
                      onBlur: () =>
                        this.handleInputFocus("company", {
                          focused: false,
                        }),
                    }}
                    hasError={!contactForm.company.valid}
                    focused={contactForm.company.focused}
                    message={
                      !contactForm.company.valid &&
                      !contactForm.company.focused &&
                      contactForm.company.value !== ""
                        ? "Your company name is required"
                        : ""
                    }
                    messageClassName={contactForm.company.messageClassName}
                  />
                </div>
                <div className="col-md-6">
                  <Input
                    label="Your Country"
                    attributes={{
                      type: "text",
                      name: "country",
                      placeholder: "Country Name",
                      required: true,
                      theme: "normal",
                      value: contactForm.country.value,
                      onChange: (event) =>
                        this.handleInputOnChange(event, "country", {
                          required: true,
                        }),
                      onFocus: () =>
                        this.handleInputFocus("country", {
                          focused: true,
                        }),
                      onBlur: () =>
                        this.handleInputFocus("country", {
                          focused: false,
                        }),
                    }}
                    hasError={!contactForm.country.valid}
                    focused={contactForm.country.focused}
                    message={
                      !contactForm.country.valid &&
                      !contactForm.country.focused &&
                      contactForm.country.value !== ""
                        ? "Your country  is required"
                        : ""
                    }
                    messageClassName={contactForm.country.messageClassName}
                  />
                </div>

                <div className="col-md-12">
                  <Textarea
                    label="Your Message"
                    attributes={{
                      type: "text",
                      name: "message",
                      placeholder: "Type your message",
                      required: true,
                      theme: "normal",
                      value: contactForm.message.value,
                      onChange: (event) =>
                        this.handleInputOnChange(event, "message", {
                          required: true,
                        }),
                      onFocus: () =>
                        this.handleInputFocus("message", {
                          focused: true,
                        }),
                      onBlur: () =>
                        this.handleInputFocus("message", {
                          focused: false,
                        }),
                    }}
                    hasError={!contactForm.message.valid}
                    focused={contactForm.message.focused}
                    message={
                      !contactForm.message.valid &&
                      !contactForm.message.focused &&
                      contactForm.message.value !== ""
                        ? "The message is required!"
                        : ""
                    }
                    messageClassName={contactForm.message.messageClassName}
                  />
                </div>
              </div>

              <div className="Contact__form__info">
                By submitting this form, you agree to our terms and conditions
                and our Privacy Policy which explains how we may collect, use
                and disclose your personal information including to thisrd
                parties.
              </div>

              <div className="Contact__form__btn">
                <Button disabled={!this.state.formIsValid}>
                  Contact Us <Arrow color="dark" />
                </Button>
              </div>
            </form>
          </div>
        </div>

        <div className="Contact__items">
          <div className="Contact__items__inner">
            <div className="row">
              <div className="col-md-4">
                <div className="Contact__item">
                  <div className="Contact__item__icon">
                    <Email />
                  </div>
                  <div className="Contact__item__content">
                    <h4>Email Us</h4>
                    <p>
                      Email us for general enquiries, sales, support and
                      partnership opportunities.
                    </p>
                    <h5>
                      <a href="mailto:hello@tinc.com.ng">hello@tinc.com.ng</a>
                    </h5>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="Contact__item">
                  <div className="Contact__item__icon">
                    <Call />
                  </div>
                  <div className="Contact__item__content">
                    <h4>Call Us</h4>
                    <p>
                      Call us to speak to any member of the team. We are happy
                      to help.
                    </p>
                    <h5>
                      <a href="tel:+2348096957186">+234 809 695 7186</a>
                    </h5>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="Contact__item">
                  <div className="Contact__item__icon">
                    <Support />
                  </div>
                  <div className="Contact__item__content">
                    <h4>Support</h4>
                    <p>
                      Reach out to one of our expert support personnel on
                      ground.{" "}
                    </p>
                    <h5>
                      <a href="mailto:support@tinc.com.ng">
                        support@tinc.com.ng
                      </a>
                    </h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="Contact__map">
          <div className="Contact__map__inner">
            <iframe
              title="Tinc map"
              src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15854.10470395794!2d3.3646352!3d6.5813177!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x103b932bf18536fd%3A0x69a2044377559c6e!2sBodds%20Support%20%3A%3A%20Service%20Center!5e0!3m2!1sen!2sng!4v1586622164439!5m2!1sen!2sng"
              width="600"
              height="450"
              frameBorder="0"
              allowFullScreen=""
              aria-hidden="false"
              tabIndex="0"
            ></iframe>
          </div>
        </div>
        <Footer color="white" />
      </div>
    );
  }
}

export default Contact;
