import React from "react";

const Next = () => {
  return (
    <svg width="75.415" height="75.415" viewBox="0 0 75.415 75.415">
      <path
        fill="none"
        stroke="rgb(255,255,255)"
        strokeLinecap="butt"
        strokeLinejoin="miter"
        strokeWidth="1"
        d="M37.70766404 2.35231505l35.35532913 35.355349L37.7076442 73.06299316"
      />
    </svg>
  );
};

export default Next;
