import React, { Component } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";

import Header from "../../components/Header";
import Footer from "../../components/Footer";
import Button from "../../components/Form/Button";
import Arrow from "../../components/Icons/Arrow";
import Computing from "../../components/Icons/CustomPc";
import LifestyleMobility from "../../components/Icons/LifestyleMobility";
import Mobility from "../../components/Icons/Mobility";
import Peripherals from "../../components/Icons/Peripherals";
import Software from "../../components/Icons/Software";
import EnterpriseSolutions from "../../components/Icons/EnterpriseSolutions";
import RequestQuote from "../../components/RequestQuote";

import HeaderDivider from "../../assets/images/itLayout/header-divider.png";
import HeaderBg from "../../assets/images/itLayout/procurement-header-bg.png";
import HeadlineImg from "../../assets/images/itLayout/procurement-headline.png";
import knowledgeImg from "../../assets/images/itLayout/knowledge.png";
import fastImg from "../../assets/images/itLayout/fast.png";
import budgetImg from "../../assets/images/itLayout/budget.png";
import salesImg from "../../assets/images/itLayout/sales.png";
import warrantyImg from "../../assets/images/itLayout/warranty.png";
import solutionsImg from "../../assets/images/itLayout/solutions.png";
import partnershipImg from "../../assets/images/itLayout/partnership.png";
import InfoImg from "../../assets/images/itLayout/procurement-info.png";
import ClientEnsure from "../../assets/images/itLayout/client-ensure.png";
import ClientHp from "../../assets/images/itLayout/client-hp.png";
import ClientSamsung from "../../assets/images/itLayout/client-samsung.png";
import ClientTd from "../../assets/images/itLayout/client-td.png";

export class ItProcurement extends Component {
  state = {
    solution: 0,
  };

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  handleOnChangeSolution = (solution) => {
    this.setState({ solution });
  };

  render() {
    return (
      <div className="ItLayout">
        <Helmet>
          <meta name="title" content="Tinc - IT Procurement" />
          <meta property="og:title" content="Tinc - IT Procurement" />
          <meta property="og:url" content="https://tinc.com.ng/procurement" />
          <link rel="canonical" href="https://tinc.com.ng/procurement" />
          <title>Tinc - IT Procurement</title>
        </Helmet>
        <Header overlayBlue />

        <div className="ItLayout__header">
          <div className="ItLayout__header__content">
            <h1>IT Procurement</h1>
            <br />
            <p>
              We supply IT products to thousands of customers, small,
              medium-sized and large businesses as well as the public sector.
            </p>
          </div>

          <img
            src={HeaderDivider}
            className="ItLayout__header__divider"
            alt="Header Divider"
          />
        </div>

        <div className="ItLayout__header__img">
          <img src={HeaderBg} alt="Header Background" />
        </div>

        <section id="headline" className="ItLayout__headline">
          <img src={HeadlineImg} alt="Headline" />

          <div className="ItLayout__headline__content">
            <h2>Procurement Of IT Devices</h2>
            <br />
            <p>
              With strong partnerships with the leading technology vendors and a
              proactive, responsive and experienced team of IT specialists,
              Tinc aims to help organisations gain a competitive edge through
              the use of current technologies tailored specifically to meet
              their needs.
            </p>
          </div>
        </section>

        <section id="solutions" className="ItLayout__solutions">
          <div className="ItLayout__solutions__title">
            <h2>Our Solutions</h2>
          </div>

          <div className="row">
            <div className="col-md-4">
              <div
                className="ItLayout__solutions__card"
                onMouseOver={() => this.handleOnChangeSolution(1)}
                onMouseOut={() => this.handleOnChangeSolution(0)}
              >
                <div className="ItLayout__solutions__card__icon">
                  <Computing
                    color={this.state.solution === 1 ? "white" : "blue"}
                  />
                </div>

                <h4>Computing</h4>
                <br />
                <p>
                  Custom built Pc, all-in-one’s, desktop, laptop (commercial and
                  consumer range available), MacBook, iMac.
                </p>
              </div>
            </div>

            <div className="col-md-4">
              <div
                className="ItLayout__solutions__card"
                onMouseOver={() => this.handleOnChangeSolution(2)}
                onMouseOut={() => this.handleOnChangeSolution(0)}
              >
                <div className="ItLayout__solutions__card__icon">
                  <LifestyleMobility
                    color={this.state.solution === 2 ? "white" : "blue"}
                  />
                </div>

                <h4>Lifestyle (IT accessories & IOT)</h4>
                <br />
                <p>
                  Earphones/Headphones, carry cases (Bags), consumer storage,
                  home networking, keyboard & mouse, webcam, smartphone
                  accessories, display monitors.
                </p>
              </div>
            </div>

            <div className="col-md-4">
              <div
                className="ItLayout__solutions__card"
                onMouseOver={() => this.handleOnChangeSolution(3)}
                onMouseOut={() => this.handleOnChangeSolution(0)}
              >
                <div className="ItLayout__solutions__card__icon">
                  <Mobility
                    color={this.state.solution === 3 ? "white" : "blue"}
                  />
                </div>

                <h4>Mobility</h4>
                <br />
                <p>Smartphones, mobiles, wearable gadgets, tablets.</p>
              </div>
            </div>

            <div className="col-md-4">
              <div
                className="ItLayout__solutions__card"
                onMouseOver={() => this.handleOnChangeSolution(4)}
                onMouseOut={() => this.handleOnChangeSolution(0)}
              >
                <div className="ItLayout__solutions__card__icon">
                  <Peripherals
                    color={this.state.solution === 4 ? "white" : "blue"}
                  />
                </div>

                <h4>Printers & Peripherials</h4>
                <br />
                <p>
                  Multi-function printers, Mono printers, all-in-one printers,
                  inkjet/laserjet printers, toners & cartridges, scanners
                  (commercial and consumer range available).
                </p>
              </div>
            </div>

            <div className="col-md-4">
              <div
                className="ItLayout__solutions__card"
                onMouseOver={() => this.handleOnChangeSolution(5)}
                onMouseOut={() => this.handleOnChangeSolution(0)}
              >
                <div className="ItLayout__solutions__card__icon">
                  <Software
                    color={this.state.solution === 5 ? "white" : "blue"}
                  />
                </div>

                <h4>Software</h4>
                <br />
                <p>
                  We have a wide assortment of software solutions spanning
                  across business productivity software to software used for
                  specific business verticals.
                </p>
              </div>
            </div>

            <div className="col-md-4">
              <div
                className="ItLayout__solutions__card"
                onMouseOver={() => this.handleOnChangeSolution(6)}
                onMouseOut={() => this.handleOnChangeSolution(0)}
              >
                <div className="ItLayout__solutions__card__icon">
                  <EnterpriseSolutions
                    color={this.state.solution === 6 ? "white" : "blue"}
                  />
                </div>

                <h4>Enterprise Equipment</h4>
                <br />
                <p>
                  We have a wide range of enterprise equipment’s such as
                  servers, checkpoints, access points, routers, extenders,
                  repeaters, surge protected PDU’s (power distribution unit)
                </p>
              </div>
            </div>
          </div>
        </section>

        <section id="whyus" className="ItLayout__whyus">
          <div className="ItLayout__whyus__inner">
            <div className="ItLayout__whyus__title">
              <h2>Why You Should Work With Us</h2>
            </div>

            <div className="row">
              <div className="col-md-4">
                <div className="ItLayout__whyus__card">
                  <div className="ItLayout__whyus__card__img">
                    <img src={knowledgeImg} alt="why us" />
                  </div>

                  <div className="ItLayout__whyus__card__content">
                    <h5>Knowledgeable & Experienced Team</h5>
                    <br />
                    <p>
                      We live and breathe technology. Our team have accrued
                      fantastic feedback for their knowledge and service levels.{" "}
                    </p>
                  </div>
                </div>
              </div>

              <div className="col-md-4">
                <div className="ItLayout__whyus__card">
                  <div className="ItLayout__whyus__card__img">
                    <img src={fastImg} alt="why us" />
                  </div>

                  <div className="ItLayout__whyus__card__content">
                    <h5>Fast Response Time</h5>
                    <br />
                    <p>
                      We offer the speed and flexibility that vendors and
                      distributors typically struggle to deliver.
                    </p>
                  </div>
                </div>
              </div>

              <div className="col-md-4">
                <div className="ItLayout__whyus__card">
                  <div className="ItLayout__whyus__card__img">
                    <img src={budgetImg} alt="why us" />
                  </div>

                  <div className="ItLayout__whyus__card__content">
                    <h5>Budget Flexibility</h5>
                    <br />
                    <p>
                      We would work with your budget to ensure you get the value
                      for your money
                    </p>
                  </div>
                </div>
              </div>

              <div className="col-md-4">
                <div className="ItLayout__whyus__card">
                  <div className="ItLayout__whyus__card__img">
                    <img src={salesImg} alt="why us" />
                  </div>

                  <div className="ItLayout__whyus__card__content">
                    <h5>After Sales Support</h5>
                    <br />
                    <p>
                      All products supply by tinc are supported by our team of
                      experts
                    </p>
                  </div>
                </div>
              </div>

              <div className="col-md-4">
                <div className="ItLayout__whyus__card">
                  <div className="ItLayout__whyus__card__img">
                    <img src={warrantyImg} alt="why us" />
                  </div>

                  <div className="ItLayout__whyus__card__content">
                    <h5>Manufacturers Warranty</h5>
                    <br />
                    <p>
                      All IT products come with the default manufacturers
                      warranty. Some of which can be redeemed at any of our
                      Service center.
                    </p>
                  </div>
                </div>
              </div>

              <div className="col-md-4">
                <div className="ItLayout__whyus__card">
                  <div className="ItLayout__whyus__card__img">
                    <img src={solutionsImg} alt="why us" />
                  </div>

                  <div className="ItLayout__whyus__card__content">
                    <h5>All Round Solutions</h5>
                    <br />
                    <p>
                      From product selection right through to configuration,
                      deployment, licensing and ongoing support, you are in safe
                      hands with Tinc
                    </p>
                  </div>
                </div>
              </div>

              <div className="col-md-4" style={{ marginLeft: "0" }}>
                <div className="ItLayout__whyus__card">
                  <div className="ItLayout__whyus__card__img">
                    <img src={partnershipImg} alt="why us" />
                  </div>

                  <div className="ItLayout__whyus__card__content">
                    <h5>Partnership With OEM'S</h5>
                    <br />
                    <p>
                      We are partners with some of the world's leading equipment
                      Manufacturers; such as Samsung and Hewlett Packard.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section id="info" className="ItLayout__info">
          <div className="ItLayout__info__inner">
            <div className="ItLayout__info__content">
              <h3>
                Tech solutions have enabled developers in Africa's leading banks
              </h3>
              <br />
              <p>
                See how we helped to provide Africas leading bank with tailored
                technology tools for her developers.
              </p>
              <br />
              <Link to="/project/uba">
                <Button>
                  Learn More <Arrow color="dark" />
                </Button>
              </Link>
            </div>
            <div className="ItLayout__info__img">
              <img src={InfoImg} alt="info" />
            </div>
          </div>
        </section>

        <section id="clients" className="ItLayout__clients">
          <div className="ItLayout__clients__inner">
            <h3>Our Trusted Partners</h3>
            <div className="ItLayout__clients__items">
              <div className="ItLayout__clients__row">
                <div className="ItLayout__clients__item">
                  <img src={ClientHp} alt="Client Hp" />
                </div>

                <div className="ItLayout__clients__item">
                  <img
                    src={ClientTd}
                    alt="Client Td"
                    style={{ marginTop: "30px" }}
                  />
                </div>

                <div className="ItLayout__clients__item">
                  <img
                    src={ClientEnsure}
                    alt="Client Ensure"
                    style={{ marginTop: "60px" }}
                  />
                </div>

                <div className="ItLayout__clients__item">
                  <img
                    src={ClientSamsung}
                    alt="Client Samsung"
                    style={{ marginTop: "80px" }}
                  />
                </div>
              </div>
            </div>
          </div>
        </section>

        <RequestQuote />
        <Footer color="white" />
      </div>
    );
  }
}

export default ItProcurement;
