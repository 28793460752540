import * as types from '../types/alert';
import { toastifyTypes, IAction } from '../../Types/general.d';

export interface IinitialState {
  isOpen: boolean;
  type: toastifyTypes;
  message: string;
}

const initialState: IinitialState = {
  isOpen: false,
  type: null,
  message: '',
};

const alertAdd = (state: IinitialState, action: IAction) => {
  return { ...state, isOpen: true, type: action.payload.type, message: action.payload.message };
};

const alertRemove = (state: IinitialState, action: IAction) => {
  return { ...state, isOpen: false, type: null, message: '' };
};

const reducer = (state = initialState, action: IAction): IinitialState => {
  switch (action.type) {
    case types.ALERT_ADD:
      return alertAdd(state, action);
    case types.ALERT_REMOVE:
      return alertRemove(state, action);
    default:
      return state;
  }
};

export default reducer;
