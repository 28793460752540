import React from "react";
import { Route, Switch, Redirect, withRouter } from "react-router-dom";

//pages
import Home from "./containers/Home";
import Services from "./containers/Services";
import Projects from "./containers/Projects";
import Consultancy from "./containers/ItConsultancy";
import Procurement from "./containers/ItProcurement";
import Repair from "./containers/HardwareRepairs";
import Contact from "./containers/Contact";

//project pages
import Project from './containers/ProjectOne';
// import Uba from "./containers/Project/Uba";
// import TotalCoop from "./containers/Project/TotalCoop";
// import Cybersoc1 from "./containers/Project/Cybersoc1";
// import Cybersoc2 from "./containers/Project/Cybersoc2";
// import Arabi from "./containers/Project/Arabi";
// import Micdee from "./containers/Project/Micdee";
// import Ad1 from "./containers/Project/Ad1";
// import Ad2 from "./containers/Project/Ad2";
// import Banco from "./containers/Project/Banco";
// import Redwire from "./containers/Project/Redwire";
// import Softcom from "./containers/Project/Softcom";
// import Quidax from "./containers/Project/Quidax";
// import CeedCap from "./containers/Project/CeedCap";
// import Helium from "./containers/Project/Helium";
// import Arnergy from "./containers/Project/Arnergy";
// import Treasures from "./containers/Project/Treasures";
// import Korapay from "./containers/Project/Korapay";
// import Interswitch from "./containers/Project/Interswitch";

function App() {
  return (
    <div className="App">
      <Switch>
        <Route path="/" exact component={Home} />
        <Route path="/services" component={Services} />
        <Route path="/projects" component={Projects} />
        <Route path="/consultancy" component={Consultancy} />
        <Route path="/procurement" component={Procurement} />
        <Route path="/repair" component={Repair} />
        <Route path="/contact" component={Contact} />
        {/* <Route path="/project/uba" component={Uba} />
        <Route path="/project/totalcoop" component={TotalCoop} />
        <Route path="/project/cybersoc1" component={Cybersoc1} />
        <Route path="/project/cybersoc2" component={Cybersoc2} />
        <Route path="/project/arabi" component={Arabi} />
        <Route path="/project/micdee" component={Micdee} />
        <Route path="/project/ad1" component={Ad1} />
        <Route path="/project/ad2" component={Ad2} />
        <Route path="/project/banco" component={Banco} />
        <Route path="/project/redwire" component={Redwire} />
        <Route path="/project/softcom" component={Softcom} />
        <Route path="/project/quidax" component={Quidax} />
        <Route path="/project/ceedcap" component={CeedCap} />
        <Route path="/project/helium" component={Helium} />
        <Route path="/project/arnergy" component={Arnergy} />
        <Route path="/project/treasures" component={Treasures} />
        <Route path="/project/korapay" component={Korapay} />
        <Route path="/project/interswitch" component={Interswitch} /> */}
        <Route path='/project/:projectId' exact component={Project} />
        <Redirect to="/" />
      </Switch>
    </div>
  );
}

export default withRouter(App);
