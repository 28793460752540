//@flow
import React from "react";

type ArrowProps = {
  color: string
};

const Arrow = (props: ArrowProps) => {
  return (
    <svg width="32.056" height="13.764" viewBox="0 0 32.056 13.764">
      <defs>
        <linearGradient
          id="linearGradient-2"
          x1="15.75"
          x2="-3.943"
          y1="6.752"
          y2="6.702"
          gradientUnits="userSpaceOnUse"
        >
          <stop
            offset="0"
            stopColor={`${
              props.color === "white" ? "rgb(255,255,255)" : "rgb(40,50,55)"
            }`}
          />
          <stop
            offset="1"
            stopColor={`${
              props.color === "white" ? "rgb(40,50,55)" : "rgb(255,255,255)"
            }`}
          />
        </linearGradient>
      </defs>
      <g>
        <path
          fill="none"
          stroke="url(#linearGradient-2)"
          strokeLinecap="round"
          strokeLinejoin="miter"
          strokeWidth=".8"
          d="M1.88185204 6.88185204h27.73722581"
        />
        <path
          fill="none"
          stroke={`${
            props.color === "white" ? "rgb(255,255,255)" : "rgb(38,77,96)"
          }`}
          strokeLinecap="round"
          strokeLinejoin="miter"
          strokeWidth=".8"
          d="M24.6263772 1.88185204l5.54744517 5-5.54744516 5"
        />
      </g>
    </svg>
  );
};

Arrow.defaultProps = {
  color: "white"
};

export default Arrow;
