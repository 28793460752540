import * as types from "../types/project";
import { IAction, IProject } from "../../Types/general.d";

export interface IinitialState {
  loading: boolean;
  projects: IProject[];
  selectedProject: IProject;
}

const initialState: IinitialState = {
  loading: false,
  projects: [],
  selectedProject: null,
};

const projectInit = (state: IinitialState, action: IAction) => {
  return { ...state, loading: true };
};

const projectFail = (state: IinitialState, action: IAction) => {
  return { ...state, loading: false };
};

const fetchProjects = (state: IinitialState, action: IAction) => {
  return { ...state, projects: action.payload, loading: false };
};

const selectProject = (state: IinitialState, action: IAction) => {
  return { ...state, selectedProject: action.payload, loading: false };
};

const reducer = (state = initialState, action: IAction): IinitialState => {
  switch (action.type) {
    case types.PROJECT_INIT:
      return projectInit(state, action);
    case types.PROJECT_FAIL:
      return projectFail(state, action);
    case types.FETCH_PROJECTS:
      return fetchProjects(state, action);
    case types.FETCH_ONE_PROJECT:
      return selectProject(state, action);
    case types.SELECT_PROJECT:
      return selectProject(state, action);
    default:
      return state;
  }
};

export default reducer;
