//@flow
import React, { Component } from "react";
import { Link } from "react-router-dom";

import { checkValidity } from "../../shared/validations";

import Input from "../../components/Form/Input";
import Button from "../../components/Form/Button";

import TincLogo from "../../assets/images/shared/tinc-logo.png";
import TincLogoWhite from "../../assets/images/shared/tinc-logo-white.png";
import FacebookIcon from "../../assets/images/footer/facebook-icon.png";
import TwitterIcon from "../../assets/images/footer/twitter-icon.png";
import InstagramIcon from "../../assets/images/footer/instagram-icon.png";
import LinkedinIcon from "../../assets/images/footer/linkedin-icon.png";

type FooterProps = {
  color: String,
};

type FooterState = {
  loginForm: {
    email: {
      value: string,
      valid: boolean,
      focused: boolean,
      messageClassName: string,
    },
  },
  formIsValid: boolean,
};

class Footer extends Component<FooterProps, FooterState> {
  static defaultProps = {
    color: "blue",
  };

  state = {
    loginForm: {
      email: {
        value: "",
        valid: false,
        focused: false,
        messageClassName: "none",
      },
    },
    formIsValid: false,
  };

  handleInputOnChange = (
    event: Object,
    elementId: string,
    validations: Object
  ) => {
    const value = event.target.value;

    const updatedFormElement = {
      ...this.state.loginForm[elementId],
      value,
      valid: checkValidity(value, validations),
      messageClassName:
        !checkValidity(value, validations) && value !== ""
          ? "input__message--error"
          : "none",
    };

    const updatedForm = {
      ...this.state.loginForm,
      [elementId]: updatedFormElement,
    };

    let formIsValid = true;
    for (let elementId in updatedForm) {
      formIsValid = updatedForm[elementId].valid && formIsValid;
    }

    this.setState({
      loginForm: updatedForm,
      formIsValid: formIsValid,
    });
  };

  handleInputFocus = (name: string, updatedState: Object) => {
    const updatedNameObject = {
      ...this.state.loginForm[name],
      ...updatedState,
    };

    const updatedForm = {
      ...this.state.loginForm,
      [name]: updatedNameObject,
    };

    this.setState({
      loginForm: updatedForm,
    });
  };

  encode = (data: Object) => {
    return Object.keys(data)
      .map(
        (key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key])
      )
      .join("&");
  };

  handleOnSubscribe = (e: Object) => {
    e.preventDefault();
    const form = e.target;
    const loginForm = this.state.loginForm;

    if (loginForm.email.valid && loginForm.email.value !== "") {
      fetch("/", {
        method: "POST",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: this.encode({
          "form-name": form.getAttribute("name"),
          email: loginForm.email.value,
        }),
      })
        .then(() => window.alert("You have successfully subscribed!"))
        .catch((error) => alert("An error occured while subscribing you!"));
    } else {
      window.alert("Invalid form submission");
    }
  };

  render() {
    const loginForm = this.state.loginForm;

    return (
      <div
        className={`Footer ${
          this.props.color === "blue" ? "Footer--blue" : "Footer--white"
        }`}
      >
        <div className="row Footer__inner">
          <div className="col-lg-3 Footer__inner__content">
            {this.props.color === "blue" ? (
              <img src={TincLogoWhite} alt="Tinc Logo White" />
            ) : (
              <img src={TincLogo} alt="Tinc Logo" />
            )}
            <p>
              Tinc IT is a technology company with a focus on providing support
              to emerging and already established organisations. Our goal is to
              help you use technology in ways that help you achieve your
              business goals efficiently.
            </p>

            <div
              className={`Footer__inner__content__socials ${this.props.color ===
                "white" && "Footer__inner__content__socials--dark"}`}
            >
              <a
                href="https://www.linkedin.com/company/tinc-group"
                target="__blank"
                rel="noopener noreferrer"
              >
                <img src={LinkedinIcon} alt="Linkedin Social Icon" />
              </a>

              <a
                href="https://twitter.com/tincgroup"
                target="__blank"
                rel="noopener noreferrer"
              >
                <img src={TwitterIcon} alt="Twitter Social Icon" />
              </a>

              <a
                href="https://instagram.com/tincgroup"
                target="__blank"
                rel="noopener noreferrer"
              >
                <img src={InstagramIcon} alt="Instagram Social Icon" />
              </a>

              <a
                href=" https://www.facebook.com/profile.php?id=100088055830423"
                target="__blank"
                rel="noopener noreferrer"
              >
                <img src={FacebookIcon} alt="Facebook Social Icon" />
              </a>
            </div>

            <div className="Footer__inner__content--greetings">
              All Rights Reserved. {"  "} With love from Tinc.
            </div>
          </div>
          <div className="col-lg-3 Footer__inner__links">
            <h4>Pages</h4>
            <Link to="/" className="Footer__inner__link">
              Home
            </Link>
            <Link to="/projects" className="Footer__inner__link">
              Our Impact
            </Link>
            <Link to="/services" className="Footer__inner__link">
              What we do
            </Link>
            <Link to="/consultancy" className="Footer__inner__link">
              IT Consultancy
            </Link>
            <Link to="/contact" className="Footer__inner__link">
              Contact Us
            </Link>
          </div>
          <div className="col-lg-3 Footer__inner__contact">
            <h4>Contact</h4>
            <br />
            <span>
              <b>Phone:</b>
              <a href="tel:+2348096957186">&nbsp; +234 809 695 7186</a>,
              <br />
              <br />
              <a href="tel:+2347040409881">+234 704 040 9881</a>
            </span>
            <span>
              <b>Email:</b>
              <a href="mailto:support@tinc.com.ng">&nbsp;support@tinc.com.ng</a>
            </span>
            <span>
              <b>Find Us:</b>&nbsp;4 Francis Oremeji,
              <br />
              <br />
              His Grace Plaza, Office 13,
              <br />
              <br />
              Last Floor, Ikeja, Lagos
              <br />
              <br />
              100001.
            </span>
            <span>
              Mon - Wed: 9am - 5pm
              <br />
              <br />
              Thurs: 10am - 5pm
              <br />
              <br />
              Fri: 9am - 5pm
            </span>
          </div>
          <div className="col-lg-3 Footer__inner__subscribe">
            <h4>Subscribe to our newsletter</h4>

            <form
              name="subscribe"
              data-netlify="true"
              data-netlify-honeypot="bot-field"
              onSubmit={this.handleOnSubscribe}
            >
              <input type="hidden" name="form-name" value="subscribe" />
              <Input
                attributes={{
                  type: "email",
                  name: "email",
                  placeholder: "Enter your email",
                  required: true,
                  theme: "default",
                  value: loginForm.email.value,
                  onChange: (event) =>
                    this.handleInputOnChange(event, "email", {
                      required: true,
                      isEmail: true,
                    }),
                  onFocus: () =>
                    this.handleInputFocus("email", {
                      focused: true,
                    }),
                  onBlur: () =>
                    this.handleInputFocus("email", {
                      focused: false,
                    }),
                }}
                hasError={!loginForm.email.valid}
                focused={loginForm.email.focused}
                message={
                  !loginForm.email.valid &&
                  !loginForm.email.focused &&
                  loginForm.email.value !== ""
                    ? "Your email is required and must be valid"
                    : ""
                }
                messageClassName={loginForm.email.messageClassName}
              />

              <div className="Footer__inner__subscribe--button">
                <Button color="brand-static" disabled={!this.state.formIsValid}>
                  Subscribe
                </Button>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

export default Footer;
