//@flow
import React from "react";

type CustomPcProps = {
  color?: string,
};

const CustomPc = (props: CustomPcProps) => {
  return (
    <svg id="Layer_1" data-name="Layer 1" viewBox="0 0 566.93 566.93">
      <g id="Page-1">
        <path
          id="Shape"
          className="cls-1"
          fill={props.color === "white" ? "#fff" : "#6d55ff"}
          d="M538.58,0H28.35A28.35,28.35,0,0,0,0,28.35V500.79a28.34,28.34,0,0,0,28.35,28.34H66.14v9.45a28.35,28.35,0,0,0,28.35,28.35h37.79a28.35,28.35,0,0,0,28.35-28.35v-9.45H406.3v9.45a28.35,28.35,0,0,0,28.35,28.35h37.79a28.35,28.35,0,0,0,28.35-28.35v-9.45h37.79a28.34,28.34,0,0,0,28.35-28.34V28.35A28.35,28.35,0,0,0,538.58,0ZM141.73,538.58a9.45,9.45,0,0,1-9.45,9.45H94.49A9.45,9.45,0,0,1,85,538.58v-9.45h56.69Zm340.16,0a9.45,9.45,0,0,1-9.45,9.45H434.65a9.45,9.45,0,0,1-9.45-9.45v-9.45h56.69ZM548,500.79a9.45,9.45,0,0,1-9.45,9.45H28.35a9.45,9.45,0,0,1-9.45-9.45V28.35a9.45,9.45,0,0,1,9.45-9.45H538.58A9.45,9.45,0,0,1,548,28.35Z"
        />
        <path
          className="cls-2"
          fill={props.color === "white" ? "#fff" : "#6d55ff"}
          d="M500.79,37.8H47.24a9.44,9.44,0,0,0-9.44,9.44V481.89a9.45,9.45,0,0,0,9.44,9.45H500.79a9.45,9.45,0,0,0,9.45-9.45V47.24A9.45,9.45,0,0,0,500.79,37.8Zm-9.45,18.89V359.06H423.5a27.67,27.67,0,0,0-5.67-28.35,28.38,28.38,0,0,0,7.37-18.9V274a28.39,28.39,0,0,0-4.82-15.88,18.93,18.93,0,0,0,4.82-12.47v-189ZM113.39,359.06a9.45,9.45,0,0,1,0-18.9H396.85a9.45,9.45,0,0,1,0,18.9ZM56.69,245.67A46.55,46.55,0,0,0,85,236.22v9.45a18.88,18.88,0,0,0,4.82,12.47A28.31,28.31,0,0,0,85,274v37.79a28.38,28.38,0,0,0,7.37,18.9,27.67,27.67,0,0,0-5.67,28.35h-30ZM406.3,274v37.79a9.45,9.45,0,0,1-9.45,9.45H113.39a9.45,9.45,0,0,1-9.45-9.45V274a9.45,9.45,0,0,1,9.45-9.45H396.85A9.45,9.45,0,0,1,406.3,274ZM85,198.43a28.34,28.34,0,0,1-28.35,28.34V94.49A28.34,28.34,0,0,1,85,122.83ZM406.3,56.69v189H103.94v-189Zm-349.61,0H85V85a46.55,46.55,0,0,0-28.35-9.45Zm0,321.26H198.43v94.49H56.69Zm160.63,94.49V378h274v94.49Z"
        />
        <path
          className="cls-3"
          fill={props.color === "white" ? "#fff" : "#6d55ff"}
          d="M378,207.87a9.45,9.45,0,0,0,9.45-9.44V94.49a9.45,9.45,0,1,0-18.9,0V198.43A9.45,9.45,0,0,0,378,207.87Z"
        />
        <path
          className="cls-4"
          fill={props.color === "white" ? "#fff" : "#6d55ff"}
          d="M340.16,207.87a9.45,9.45,0,0,0,9.45-9.44V94.49a9.45,9.45,0,1,0-18.9,0V198.43A9.45,9.45,0,0,0,340.16,207.87Z"
        />
        <path
          className="cls-5"
          fill={props.color === "white" ? "#fff" : "#6d55ff"}
          d="M163.37,205.13a9.44,9.44,0,0,0,13.42,0l7.75-7.74a56.7,56.7,0,0,0,65.57,0l7.75,7.74a9.49,9.49,0,1,0,13.42-13.41L263.53,184a56.71,56.71,0,0,0,0-65.58l7.75-7.74a9.49,9.49,0,1,0-13.42-13.42L250.11,105a56.66,56.66,0,0,0-65.57,0l-7.75-7.75a9.49,9.49,0,1,0-13.42,13.42l7.75,7.74a56.68,56.68,0,0,0,0,65.58l-7.75,7.75a9.45,9.45,0,0,0,0,13.41Zm91.75-53.95a37.8,37.8,0,1,1-37.8-37.79A37.8,37.8,0,0,1,255.12,151.18Z"
        />
        <circle
          id="Oval"
          className="cls-6"
          fill={props.color === "white" ? "#fff" : "#6d55ff"}
          cx="122.83"
          cy="406.3"
          r="9.45"
        />
        <circle
          className="cls-7"
          fill={props.color === "white" ? "#fff" : "#6d55ff"}
          cx="122.83"
          cy="444.09"
          r="9.45"
        />
        <circle
          className="cls-8"
          fill={props.color === "white" ? "#fff" : "#6d55ff"}
          cx="94.49"
          cy="425.2"
          r="9.45"
        />
        <circle
          className="cls-9"
          fill={props.color === "white" ? "#fff" : "#6d55ff"}
          cx="151.18"
          cy="425.2"
          r="9.45"
        />
        <path
          className="cls-10"
          fill={props.color === "white" ? "#fff" : "#6d55ff"}
          d="M189,302.36h18.89a9.45,9.45,0,0,0,0-18.9H189a9.45,9.45,0,1,0,0,18.9Z"
        />
        <path
          className="cls-11"
          fill={props.color === "white" ? "#fff" : "#6d55ff"}
          d="M245.67,302.36h56.69a9.45,9.45,0,0,0,0-18.9H245.67a9.45,9.45,0,0,0,0,18.9Z"
        />
      </g>
    </svg>
  );
};

export default CustomPc;
