import React, { Component } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";

import Header from "../../components/Header";
import Footer from "../../components/Footer";
import Button from "../../components/Form/Button";
import Arrow from "../../components/Icons/Arrow";
import Infrastructure from "../../components/Icons/Infrastructure";
import TechnicalAdvice from "../../components/Icons/TechnicalAdvice";
import HardwareRepair from "../../components/Icons/HardwareRepair";
import ProactiveMonitoring from "../../components/Icons/ProactiveMonitoring";
import RequestQuote from "../../components/RequestQuote";

import HeaderDivider from "../../assets/images/itLayout/header-divider.png";
import HeaderBg from "../../assets/images/itLayout/itsolutions-header-bg.png";
import HeadlineImg from "../../assets/images/itLayout/hardwareRepair-headline.png";
import knowledgeImg from "../../assets/images/itLayout/knowledge.png";
import fastImg from "../../assets/images/itLayout/fast.png";
import budgetImg from "../../assets/images/itLayout/budget.png";
import salesImg from "../../assets/images/itLayout/sales.png";
import warrantyImg from "../../assets/images/itLayout/warranty.png";
import solutionsImg from "../../assets/images/itLayout/solutions.png";
import partnershipImg from "../../assets/images/itLayout/partnership.png";
import InfoImg from "../../assets/images/itLayout/repair-info.png";
import ClientEnsure from "../../assets/images/itLayout/client-ensure.png";
import ClientHp from "../../assets/images/itLayout/client-hp.png";
import ClientSamsung from "../../assets/images/itLayout/client-samsung.png";
import ClientTd from "../../assets/images/itLayout/client-td.png";

export class HardwareRepairs extends Component {
  state = {
    solution: 0,
  };

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  handleOnChangeSolution = (solution) => {
    this.setState({ solution });
  };

  render() {
    return (
      <div className="ItLayout">
        <Helmet>
          <meta name="title" content="Tinc - Repair & Maintenance" />
          <meta property="og:title" content="Tinc - Repair & Maintenance" />
          <meta property="og:url" content="https://tinc.com.ng/repair" />
          <link rel="canonical" href="https://tinc.com.ng/repair" />
          <title>Tinc - Managed IT Solutions</title>
        </Helmet>
        <Header overlayBlue />

        <div className="ItLayout__header">
          <div
            className="ItLayout__header__content"
            style={{ maxWidth: "600px" }}
          >
            <h1>Managed IT Services</h1>
            <br />
            <p>
              Our End-to-End Business support solution for your organization
              helps you focus on growing your business while we focus on your
              tech infrastructures.
            </p>
          </div>

          <img
            src={HeaderDivider}
            className="ItLayout__header__divider"
            alt="Header Divider"
          />
        </div>

        <div className="ItLayout__header__img">
          <img src={HeaderBg} alt="Header Background" />
        </div>

        <section id="headline" className="ItLayout__headline">
          <img src={HeadlineImg} alt="Headline" />

          <div className="ItLayout__headline__content">
            <h2>End-To-End Business Support</h2>
            <br />
            <p>
              Tinc provides a full range of flexible IT support services that
              keep organizations operational and ensure systems are performing
              to their full potential.
              <br />
              <br />
              We’ve been providing managed IT services to businesses of all
              sizes across multiple industries for over 3 years. During this
              time, we’ve assembled an in-house team of qualified technicians
              with breadth and depth of expertise. We’ve also gained
              certifications and developed strong partnerships with the leading
              technology vendors. <br />
              <br />
              Over 80% of our support customers have been with us for more than
              5 years which is testament to our positive reputation, our
              industry leading resolution times and consistently superior
              customer service.
            </p>
          </div>
        </section>

        <section id="solutions" className="ItLayout__solutions">
          <div className="ItLayout__solutions__title">
            <h2>Our Solutions</h2>
          </div>

          <div className="row">
            <div className="col-md-4">
              <div
                className="ItLayout__solutions__card"
                onMouseOver={() => this.handleOnChangeSolution(1)}
                onMouseOut={() => this.handleOnChangeSolution(0)}
              >
                <div className="ItLayout__solutions__card__icon">
                  <Infrastructure
                    color={this.state.solution === 1 ? "white" : "blue"}
                  />
                </div>

                <h4>Infrastructure</h4>
                <br />
                <p>
                  We provide hardware support for all the key vendors including
                  current and legacy equipment.
                </p>
              </div>
            </div>

            <div className="col-md-4">
              <div
                className="ItLayout__solutions__card"
                onMouseOver={() => this.handleOnChangeSolution(2)}
                onMouseOut={() => this.handleOnChangeSolution(0)}
              >
                <div className="ItLayout__solutions__card__icon">
                  <TechnicalAdvice
                    color={this.state.solution === 2 ? "white" : "blue"}
                  />
                </div>

                <h4>Technical Advice</h4>
                <br />
                <p>
                  The expertise you need to maximize the value of your
                  technology investment.
                </p>
              </div>
            </div>

            <div className="col-md-4">
              <div
                className="ItLayout__solutions__card"
                onMouseOver={() => this.handleOnChangeSolution(3)}
                onMouseOut={() => this.handleOnChangeSolution(0)}
              >
                <div className="ItLayout__solutions__card__icon">
                  <HardwareRepair
                    color={this.state.solution === 3 ? "white" : "blue"}
                  />
                </div>

                <h4>Hardware Repairs & Maintenance</h4>
                <br />
                <p>
                  Solutions to both in-warranty and out-of warranty devices.
                </p>
              </div>
            </div>

            <div className="col-md-4">
              <div
                className="ItLayout__solutions__card"
                onMouseOver={() => this.handleOnChangeSolution(5)}
                onMouseOut={() => this.handleOnChangeSolution(0)}
              >
                <div className="ItLayout__solutions__card__icon">
                  <ProactiveMonitoring
                    color={this.state.solution === 5 ? "white" : "blue"}
                  />
                </div>

                <h4>Proactive Monitoring</h4>
                <br />
                <p>
                  Sophisticated monitoring and reporting tools for efficient,
                  proactive management of your IT infrastructure.
                </p>
              </div>
            </div>
          </div>
        </section>

        <section id="whyus" className="ItLayout__whyus">
          <div className="ItLayout__whyus__inner">
            <div className="ItLayout__whyus__title">
              <h2>Why You Should Work With Us</h2>
            </div>

            <div className="row">
              <div className="col-md-4">
                <div className="ItLayout__whyus__card">
                  <div className="ItLayout__whyus__card__img">
                    <img src={knowledgeImg} alt="why us" />
                  </div>

                  <div className="ItLayout__whyus__card__content">
                    <h5>Knowledgeable & Experienced Team</h5>
                    <br />
                    <p>
                      We live and breathe technology. Our team have accrued
                      fantastic feedback for their knowledge and service levels.{" "}
                    </p>
                  </div>
                </div>
              </div>

              <div className="col-md-4">
                <div className="ItLayout__whyus__card">
                  <div className="ItLayout__whyus__card__img">
                    <img src={fastImg} alt="why us" />
                  </div>

                  <div className="ItLayout__whyus__card__content">
                    <h5>Fast Response Time</h5>
                    <br />
                    <p>
                      We offer the speed and flexibility that vendors and
                      distributors typically struggle to deliver.
                    </p>
                  </div>
                </div>
              </div>

              <div className="col-md-4">
                <div className="ItLayout__whyus__card">
                  <div className="ItLayout__whyus__card__img">
                    <img src={budgetImg} alt="why us" />
                  </div>

                  <div className="ItLayout__whyus__card__content">
                    <h5>Budget Flexibility</h5>
                    <br />
                    <p>
                      We would work with your budget to ensure you get the value
                      for your money
                    </p>
                  </div>
                </div>
              </div>

              <div className="col-md-4">
                <div className="ItLayout__whyus__card">
                  <div className="ItLayout__whyus__card__img">
                    <img src={salesImg} alt="why us" />
                  </div>

                  <div className="ItLayout__whyus__card__content">
                    <h5>After Sales Support</h5>
                    <br />
                    <p>
                      All products supply by tinc are supported by our team of
                      experts
                    </p>
                  </div>
                </div>
              </div>

              <div className="col-md-4">
                <div className="ItLayout__whyus__card">
                  <div className="ItLayout__whyus__card__img">
                    <img src={warrantyImg} alt="why us" />
                  </div>

                  <div className="ItLayout__whyus__card__content">
                    <h5>Manufacturers Warranty</h5>
                    <br />
                    <p>
                      All IT products come with the default manufacturers
                      warranty. Some of which can be redeemed at any of our
                      Service center.
                    </p>
                  </div>
                </div>
              </div>

              <div className="col-md-4">
                <div className="ItLayout__whyus__card">
                  <div className="ItLayout__whyus__card__img">
                    <img src={solutionsImg} alt="why us" />
                  </div>

                  <div className="ItLayout__whyus__card__content">
                    <h5>All Round Solutions</h5>
                    <br />
                    <p>
                      From product selection right through to configuration,
                      deployment, licensing and ongoing support, you are in safe
                      hands with Tinc
                    </p>
                  </div>
                </div>
              </div>

              <div className="col-md-4" style={{ marginLeft: "0" }}>
                <div className="ItLayout__whyus__card">
                  <div className="ItLayout__whyus__card__img">
                    <img src={partnershipImg} alt="why us" />
                  </div>

                  <div className="ItLayout__whyus__card__content">
                    <h5>Partnership With OEM'S</h5>
                    <br />
                    <p>
                      We are partners with some of the world's leading equipment
                      Manufacturers; such as Samsung and Hewlett Packard.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section id="info" className="ItLayout__info">
          <div className="ItLayout__info__inner">
            <div className="ItLayout__info__content">
              <h3>
              AD Consulting can focus on their core business while we manage their IT Services.
              </h3>
              <br />
              <p>
              See how we provided one of Africas award winning architecture firm with Storage Solutions that improved efficiency.
              </p>
              <br />
              <Link to="/project/ad2">
                <Button>
                  Learn More <Arrow color="dark" />
                </Button>
              </Link>
            </div>
            <div className="ItLayout__info__img">
              <img src={InfoImg} alt="info" />
            </div>
          </div>
        </section>

        <section id="clients" className="ItLayout__clients">
          <div className="ItLayout__clients__inner">
            <h3>Our Trusted Partners</h3>
            <div className="ItLayout__clients__items">
              <div className="ItLayout__clients__row">
                <div className="ItLayout__clients__item">
                  <img src={ClientHp} alt="Client Hp" />
                </div>

                <div className="ItLayout__clients__item">
                  <img
                    src={ClientTd}
                    alt="Client Td"
                    style={{ marginTop: "30px" }}
                  />
                </div>

                <div className="ItLayout__clients__item">
                  <img
                    src={ClientEnsure}
                    alt="Client Ensure"
                    style={{ marginTop: "60px" }}
                  />
                </div>

                <div className="ItLayout__clients__item">
                  <img
                    src={ClientSamsung}
                    alt="Client Samsung"
                    style={{ marginTop: "80px" }}
                  />
                </div>
              </div>
            </div>
          </div>
        </section>

        <RequestQuote />
        <Footer color="white" />
      </div>
    );
  }
}

export default HardwareRepairs;
