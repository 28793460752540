//@flow
import React from "react";

type TechnicalAdviceProps = {
  color?: string,
};

const TechnicalAdvice = (props: TechnicalAdviceProps) => {
  return (
    <svg id="Layer_1" data-name="Layer 1" viewBox="0 0 566.93 566.93">
      <path
        className="cls-1"
        fill={props.color === "white" ? "#fff" : "#6d55ff"}
        d="M460.49,493.78l-21.21-63.52a64.13,64.13,0,0,0-49.39-42.73l-61.25-11.12v-4.73A92.37,92.37,0,0,0,356,347.47h18.5A27.5,27.5,0,0,0,402,320V210.31c0-65.54-53.45-118.87-119.15-118.87S163.66,144.77,163.66,210.31v64a36.67,36.67,0,0,0,29.23,35.82A91.61,91.61,0,0,0,237,371.68v4.73l-61.26,11.12a64.12,64.12,0,0,0-49.38,42.73l-3.66,11L91.46,410.1a9.14,9.14,0,0,1,0-12.94l36.2-36.11L59.71,293.26,18.8,334.08a63.89,63.89,0,0,0,0,90.51L142.66,548.17a64.23,64.23,0,0,0,90.45.28l44.64-40.81-13.92-13.86ZM383.63,320a9.16,9.16,0,0,1-9.17,9.14h-7.71a90.32,90.32,0,0,0,6-19,36.55,36.55,0,0,0,10.9-4.33Zm-9.17-30v-31.5a18.11,18.11,0,0,1,0,31.5Zm-183.3,0a18.11,18.11,0,0,1,0-31.5Zm0-61.47V239a36.12,36.12,0,0,0-9.17,3.79V210.31c0-55.46,45.23-100.58,100.82-100.58s100.82,45.12,100.82,100.58v32.52a36.06,36.06,0,0,0-9.17-3.79V228.6H365.3A101,101,0,0,1,293.54,199l-10.73-10.71L272.08,199a101,101,0,0,1-71.76,29.65Zm18.33,64V246.54A119,119,0,0,0,282.81,214a118.89,118.89,0,0,0,73.32,32.5v46.07a72.44,72.44,0,0,1-9.92,36.57h-63.4v18.29h48.34a73.24,73.24,0,0,1-121.66-54.86ZM310.3,379.83v.43L282.81,407.7l-27.5-27.44v-.43a91.68,91.68,0,0,0,55,0ZM143.72,436A45.81,45.81,0,0,1,179,405.51l64.06-11.61,39.75,39.65,39.74-39.64,64.05,11.61a45.79,45.79,0,0,1,35.28,30.53l13.19,39.44H245.5l-35.7-35.62-39.67,35.8a9.37,9.37,0,0,1-13,0l-20-20Zm-84-116.92,42,41.93-10,9.93-42-41.93ZM220.41,535.26a45.91,45.91,0,0,1-64.8,0L31.74,411.67a45.64,45.64,0,0,1,0-64.65l5-5,42,41.93-.33.33a27.44,27.44,0,0,0,0,38.8l65.72,65.57a27.38,27.38,0,0,0,19.45,8,27,27,0,0,0,19.11-7.72l.69-.61,42.22,42.12Zm18.79-17.19-42.13-42,12.11-10.92,42.06,42Z"
      />
      <path
        className="cls-2"
        fill={props.color === "white" ? "#fff" : "#6d55ff"}
        d="M539.43,0H411.12a27.49,27.49,0,0,0-27.49,27.43v73.15A27.5,27.5,0,0,0,411.12,128H435.6l-9.48,55.14L514.57,128h24.86a27.5,27.5,0,0,0,27.5-27.44V27.43A27.5,27.5,0,0,0,539.43,0Zm9.17,100.58a9.18,9.18,0,0,1-9.17,9.15H509.31L451.1,146l6.24-36.29H411.12a9.17,9.17,0,0,1-9.16-9.15V27.43a9.16,9.16,0,0,1,9.16-9.14H539.43a9.17,9.17,0,0,1,9.17,9.14Z"
      />
      <rect className="cls-3"
      fill={props.color === "white" ? "#fff" : "#6d55ff"} x="420.29" y="36.58" width="109.98" height="18.29" />
      <rect className="cls-4"
      fill={props.color === "white" ? "#fff" : "#6d55ff"} x="420.29" y="73.15" width="73.32" height="18.29" />
      <rect className="cls-5"
      fill={props.color === "white" ? "#fff" : "#6d55ff"} x="511.94" y="73.15" width="18.33" height="18.29" />
      <path
        className="cls-6"
        fill={props.color === "white" ? "#fff" : "#6d55ff"}
        d="M282.81,27.43c-119.75,0-222.91,82.65-249.72,197.92L15.72,196.47,0,205.88l32.22,53.55L85.9,227.29l-9.44-15.68L51.63,226.48C77.6,121,172.65,45.72,282.81,45.72a238.86,238.86,0,0,1,70.61,10.61l5.42-17.47A256.93,256.93,0,0,0,282.81,27.43Z"
      />
      <path
        className="cls-7"
        fill={props.color === "white" ? "#fff" : "#6d55ff"}
        d="M562.47,202.47l-53.68-32.14-32.2,53.56,15.72,9.41,16-26.67A237.52,237.52,0,0,1,468,433.15l14.24,11.52A255.61,255.61,0,0,0,526.11,202L553,218.15Z"
      />
    </svg>
  );
};

export default TechnicalAdvice;
