import React from "react";

const Email = () => {
  return (
    <svg id="Layer_1" data-name="Layer 1" viewBox="0 0 566.93 566.93">
      <path
        className="cls-1"
        fill="#6d55ff"
        d="M526.37,95.15H106.07a36.61,36.61,0,0,0-36.58,36.57v78.06H12.18a8.19,8.19,0,1,0,0,16.37h98.25a8.19,8.19,0,1,0,0-16.37H85.87V131.72a20.05,20.05,0,0,1,1.37-7.25l159,159-159,159a20.1,20.1,0,0,1-1.37-7.25V381.72a8.19,8.19,0,1,0-16.38,0v53.49a36.62,36.62,0,0,0,36.58,36.57h420.3a36.62,36.62,0,0,0,36.57-36.57V131.72A36.61,36.61,0,0,0,526.37,95.15ZM386.2,283.46l159-159a20.05,20.05,0,0,1,1.37,7.25V435.21a20.1,20.1,0,0,1-1.37,7.25ZM526.37,111.52a20,20,0,0,1,7.24,1.37L351,295.55a49.17,49.17,0,0,1-69.47,0L98.82,112.89a20.05,20.05,0,0,1,7.25-1.37ZM106.07,455.41A20.05,20.05,0,0,1,98.82,454l159-159,12.09,12.09a65.56,65.56,0,0,0,92.63,0L374.62,295l159,159a20.14,20.14,0,0,1-7.25,1.37Z"
      />
      <path
        className="cls-2"
        fill="#6d55ff"
        d="M77.68,242.53a8.19,8.19,0,0,0-8.19,8.18v24.57H12.18a8.19,8.19,0,1,0,0,16.37h131a8.19,8.19,0,1,0,0-16.37H85.87V250.71A8.19,8.19,0,0,0,77.68,242.53Z"
      />
      <path
        className="cls-3"
        fill="#6d55ff"
        d="M110.43,357.15a8.19,8.19,0,1,0,0-16.37H85.87V316.22a8.19,8.19,0,1,0-16.38,0v24.56H12.18a8.19,8.19,0,1,0,0,16.37Z"
      />
    </svg>
  );
};

export default Email;
